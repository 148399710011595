// src/components/ImageViewer.tsx

import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
    useMemo,
  } from 'react';
  import {
    getBaseFilename,
    parseBoundingBoxes,
  } from '../utils/helpers';
  import { usePredictions } from '../context/PredictionsContext';
  import { StudyData, ViewerMetrics, ViewerMode, NpzData } from './types';
  import { type SpatialGroup } from '../utils/reportGenerator';

  interface ImageViewerProps {
    study: StudyData;
    selectedSeriesId: string | undefined;
    currentSlice: number;
    setCurrentSlice: (slice: number | ((prev: number) => number)) => void;
    viewerMetrics: ViewerMetrics;
    setViewerMetrics: React.Dispatch<React.SetStateAction<ViewerMetrics>>;
    windowCenter: number;
    setWindowCenter: (center: number | ((prev: number) => number)) => void;
    windowWidth: number;
    setWindowWidth: (width: number | ((prev: number) => number)) => void;
    viewerMode: ViewerMode;
    setViewerMode: (mode: ViewerMode) => void;
    totalSlices: number;
    setTotalSlices: (slices: number) => void;
    handleWindowPreset: (preset: { center: number; width: number }) => void;
    currentSeries?: NpzData;
    availableSlices: number[];
    baseModelPredictions: Record<string, any>;
    experimentalModelPredictions: Record<string, any>;
    spatialGroups: SpatialGroup[];
    activeGroupId: string | null;
    measurements: Distance[];
    setMeasurements: React.Dispatch<React.SetStateAction<Distance[]>>;
    onClearMeasurements: () => void;
  }

  interface MousePosition {
    x: number;
    y: number;
    value: number;
    windowedValue: number;
  }

  export interface Point {
    x: number;
    y: number;
  }

  // Update the Distance interface
  export interface Distance {
    start: Point;
    end: Point | null;
    pixelSpacing?: { rowSpacing: number, colSpacing: number };
  }

  const WINDOW_PRESETS = {
    SOFT_TISSUE: { center: 40, width: 400 }, // Hotkey: 1
    LUNG: { center: -600, width: 1500 }, // Hotkey: 2
    BONE: { center: 400, width: 1800 }, // Hotkey: 3
    BRAIN: { center: 40, width: 80 }, // Hotkey: 4
    LIVER: { center: 30, width: 150 }, // Hotkey: 5
  } as const;

  const ImageViewer: React.FC<ImageViewerProps> = ({
    study,
    selectedSeriesId,
    currentSlice,
    setCurrentSlice,
    viewerMetrics,
    setViewerMetrics,
    windowCenter,
    setWindowCenter,
    windowWidth,
    setWindowWidth,
    viewerMode,
    setViewerMode,
    totalSlices,
    setTotalSlices,
    handleWindowPreset,
    baseModelPredictions,
    experimentalModelPredictions,
    spatialGroups,
    activeGroupId,
    measurements,
    setMeasurements,
    onClearMeasurements 
  }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [lastMousePosition, setLastMousePosition] = useState<{
      x: number;
      y: number;
    } | null>(null);
    const [activeMeasurement, setActiveMeasurement] = useState<Distance | null>(
      null
    );
    const [mousePosition, setMousePosition] = useState<MousePosition | null>(null);
    const [showHotkeys, setShowHotkeys] = useState(false);

    // Compute flippedCurrentSlice once
    const flippedCurrentSlice = useMemo(() => totalSlices - currentSlice - 1, [totalSlices, currentSlice]);

    
    const renderMeasurements = useCallback(
      (ctx: CanvasRenderingContext2D) => {
        if (!viewerMetrics.showMeasurements) return;

        const allMeasurements = [...measurements];
        if (activeMeasurement?.end) {
          allMeasurements.push(activeMeasurement);
        }

        ctx.save();
        ctx.strokeStyle = '#00ff00';
        ctx.fillStyle = '#00ff00';
        ctx.lineWidth = 2;
        ctx.font = '14px Arial';

        allMeasurements.forEach((measurement) => {
          if (!measurement.end) return;

          // Draw line
          ctx.beginPath();
          ctx.moveTo(measurement.start.x, measurement.start.y);
          ctx.lineTo(measurement.end.x, measurement.end.y);
          ctx.stroke();

          // Draw distance label
          const distance = calculateDistance(
            measurement.start,
            measurement.end,
            measurement.pixelSpacing
          );
          const midX = (measurement.start.x + measurement.end.x) / 2;
          const midY = (measurement.start.y + measurement.end.y) / 2;

          ctx.fillText(`${distance.toFixed(1)} mm`, midX + 5, midY - 5);
        });

        ctx.restore();
      },
      [measurements, activeMeasurement, viewerMetrics.showMeasurements]
    );


    const renderBoundingBoxes = useCallback(
      (ctx: CanvasRenderingContext2D) => {
        const modelSeriesId = study.modelSelectedSeriesId;
        if (!modelSeriesId || !viewerMetrics.showBoundingBoxes) {
          return;
        }

        // Use the selected model's predictions
        const predictions =
          viewerMetrics.selectedModel === 'base'
            ? baseModelPredictions
            : experimentalModelPredictions;

        if (!predictions) return;

        const bboxes = parseBoundingBoxes(predictions);

        ctx.imageSmoothingEnabled = true;

        // Render individual findings
        Object.entries(bboxes).forEach(([condition, data]) => {
          if (!viewerMetrics.selectedConditions.has(condition)) return;

          const sliceBoxes = data.slices;

          sliceBoxes.forEach((sliceBox: any) => {
            const { bbox, slice_idx } = sliceBox;

            // Use flippedCurrentSlice for comparison
            if (slice_idx !== flippedCurrentSlice) return;

            const [organ, finding] = condition.split('/');
            const fullLabel = `${organ}${finding ? `: ${finding}` : ''}`;

            // Get class status
            const classKey = `class_${condition}`;
            const status = predictions[classKey] as string;

            // Set colors based on status
            const strokeColor = status === 'detected'
              ? 'rgba(0, 255, 0, 0.8)'   // Green for detected
              : 'rgba(255, 165, 0, 0.8)'; // Orange for suspected

            // Draw bounding box
            ctx.strokeStyle = strokeColor;
            ctx.lineWidth = 2;
            ctx.strokeRect(
              bbox.xmin,
              bbox.ymin,
              bbox.xmax - bbox.xmin,
              bbox.ymax - bbox.ymin
            );

            // Draw label background
            ctx.font = '600 13px system-ui';
            const textMetrics = ctx.measureText(fullLabel);
            const padding = 6;
            const labelHeight = 24;

            ctx.fillStyle = strokeColor;
            ctx.fillRect(
              bbox.xmin - 1,
              bbox.ymin - labelHeight,
              textMetrics.width + padding * 2,
              labelHeight
            );

            // Draw label text
            ctx.fillStyle = 'white';
            ctx.textBaseline = 'middle';
            ctx.fillText(
              fullLabel,
              bbox.xmin + padding,
              bbox.ymin - labelHeight / 2
            );
          });
        });

        // Render group bounding boxes
        spatialGroups.forEach((group: SpatialGroup) => {
          const groupBBoxKey = `group_bbox_${group.id}`;

          // Check if the group is either active or selected in viewerMetrics
          const isActiveGroup = activeGroupId === group.id;
          const isSelectedCondition = viewerMetrics.selectedConditions.has(groupBBoxKey);

          // Proceed if the group is active or selected
          if (isActiveGroup || isSelectedCondition) {
            // Convert the group's slice range to display slice numbers
            const displaySliceMin = totalSlices - group.bbox.endSlice - 1;
            const displaySliceMax = totalSlices - group.bbox.startSlice - 1;

            // Check if current slice is within the display slice range
            if (
              currentSlice >= displaySliceMin &&
              currentSlice <= displaySliceMax
            ) {
              // Use primary color (blueish) instead of accent (purple)
              ctx.strokeStyle = isActiveGroup
                ? 'rgba(79, 70, 229, 0.9)'  // --color-primary
                : 'rgba(129, 140, 248, 0.9)'; // --color-primary-light

              ctx.lineWidth = isActiveGroup ? 3 : 2;
              ctx.setLineDash(isActiveGroup ? [] : [5, 5]);

              // Calculate center and radius
              const centerX = (group.bbox.minX + group.bbox.maxX) / 2;
              const centerY = (group.bbox.minY + group.bbox.maxY) / 2;
              const width = group.bbox.maxX - group.bbox.minX;
              const height = group.bbox.maxY - group.bbox.minY;
              const radius = Math.max(width, height) / 2;

              // Draw circle with no fill
              ctx.beginPath();
              ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
              ctx.stroke();

              // No fill - background is clear
              // ctx.fillStyle is not needed since we're not filling

              ctx.setLineDash([]); // Reset line style
            }
          }
        });
      },
      [
        study,
        viewerMetrics.showBoundingBoxes,
        viewerMetrics.selectedConditions,
        viewerMetrics.selectedModel,
        flippedCurrentSlice,
        baseModelPredictions,
        experimentalModelPredictions,
        study.modelSelectedSeriesId,
        spatialGroups,
        activeGroupId,
      ]
    );

    const renderSlice = useCallback(() => {
      if (!canvasRef.current || !study || study.series.length === 0) return;
      const ctx = canvasRef.current.getContext('2d');
      if (!ctx) return;

      const currentSeries = study.series.find(
        s => getBaseFilename(s.filename) === selectedSeriesId
      );

      if (!currentSeries?.data?.[currentSlice]) return;

      const { data, dims } = currentSeries;
      const [, height, width] = dims;

      // Calculate the maximum size that maintains aspect ratio and fits in viewport
      const maxHeight = window.innerHeight - 32; // Account for padding
      const maxWidth =
        canvasRef.current.parentElement?.clientWidth || window.innerWidth;

      const scale = Math.min(maxWidth / width, maxHeight / height);

      // Set canvas size to maintain aspect ratio while fitting viewport
      canvasRef.current.width = width;
      canvasRef.current.height = height;
      canvasRef.current.style.width = `${width * scale}px`;
      canvasRef.current.style.height = `${height * scale}px`;

      // Get the current slice data
      const sliceData = data[currentSlice];
      if (!sliceData) return;

      // Create ImageData
      const imageData = ctx.createImageData(width, height);
      const pixelData = imageData.data;

      // Apply windowing
      const windowMin = windowCenter - windowWidth / 2;
      const windowMax = windowCenter + windowWidth / 2;

      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          const value = sliceData[y][x];

          // Apply windowing
          let normalizedValue: number;
          if (value < windowMin) {
            normalizedValue = 0;
          } else if (value > windowMax) {
            normalizedValue = 255;
          } else {
            normalizedValue = Math.round(
              ((value - windowMin) / windowWidth) * 255
            );
          }

          const pixelIndex = (y * width + x) * 4;
          pixelData[pixelIndex] = normalizedValue; // R
          pixelData[pixelIndex + 1] = normalizedValue; // G
          pixelData[pixelIndex + 2] = normalizedValue; // B
          pixelData[pixelIndex + 3] = 255; // A
        }
      }

      ctx.putImageData(imageData, 0, 0);
      renderBoundingBoxes(ctx);
      renderMeasurements(ctx);
    }, [
      study,
      selectedSeriesId,
      currentSlice,
      windowCenter,
      windowWidth,
      renderBoundingBoxes,
      renderMeasurements,
    ]);
    
    const getPixelSpacing = (): { rowSpacing: number, colSpacing: number } => {
      if (!selectedSeriesId || !study.metadata?.[selectedSeriesId]) {
        console.warn('No metadata available for series:', selectedSeriesId);
        return { rowSpacing: 1, colSpacing: 1 }; // Default fallback
      }
    
      const metadata = study.metadata[selectedSeriesId];
      const pixelSpacing = metadata.PixelSpacing;
    
      // Add debug logging
      console.debug('Metadata for series:', {
        seriesId: selectedSeriesId,
        metadata: metadata,
        pixelSpacing: pixelSpacing
      });
    
      // Handle different formats of pixel spacing
      let spacingArray: number[] = [];
      
      if (Array.isArray(pixelSpacing)) {
        // Convert string values to numbers if needed
        spacingArray = pixelSpacing.map(Number);
      } else if (typeof pixelSpacing === 'string') {
        // Handle string format (e.g., "[0.8515625, 0.8515625]")
        try {
          // Remove brackets and split by comma
          const cleanString = pixelSpacing.replace(/[\[\]]/g, '');
          spacingArray = cleanString.split(',').map(s => Number(s.trim()));
        } catch (e) {
          console.warn('Error parsing pixel spacing string:', pixelSpacing);
          return { rowSpacing: 1, colSpacing: 1 };
        }
      }
    
      // Validate the parsed values
      if (spacingArray.length >= 2 && !spacingArray.some(isNaN)) {
        const [rowSpacing, colSpacing] = spacingArray;
        console.debug('Using pixel spacing:', { rowSpacing, colSpacing });
        return { rowSpacing, colSpacing };
      }
    
      console.warn('Invalid pixel spacing values:', pixelSpacing);
      return { rowSpacing: 1, colSpacing: 1 };
    };

    const calculateDistance = (
      start: Point,
      end: Point,
      pixelSpacing?: { rowSpacing: number, colSpacing: number }
    ): number => {
      const spacing = pixelSpacing || getPixelSpacing();
      
      // Use row spacing for y-direction and column spacing for x-direction
      const dx = (end.x - start.x) * spacing.colSpacing;
      const dy = (end.y - start.y) * spacing.rowSpacing;
      return Math.sqrt(dx * dx + dy * dy);
    };
    

    const getImageCoordinates = useCallback(
      (clientX: number, clientY: number): { x: number; y: number } => {
        if (!canvasRef.current) return { x: 0, y: 0 };

        const rect = canvasRef.current.getBoundingClientRect();
        return {
          x: Math.floor(
            ((clientX - rect.left) / rect.width) * canvasRef.current.width
          ),
          y: Math.floor(
            ((clientY - rect.top) / rect.height) * canvasRef.current.height
          ),
        };
      },
      []
    );

    const getPixelValue = useCallback(
      (x: number, y: number): { raw: number; windowed: number } => {
        const currentSeries = study.series.find(
          s => getBaseFilename(s.filename) === selectedSeriesId
        );
        if (!currentSeries) return { raw: 0, windowed: 0 };

        const sliceData = currentSeries.data[currentSlice];
        if (!sliceData) return { raw: 0, windowed: 0 };

        if (y < 0 || y >= sliceData.length || !sliceData[y])
          return { raw: 0, windowed: 0 };
        if (x < 0 || x >= sliceData[y].length)
          return { raw: 0, windowed: 0 };

        const rawValue = sliceData[y][x];

        // Apply windowing
        const windowMin = windowCenter - windowWidth / 2;
        const windowedValue = Math.round(
          ((rawValue - windowMin) / windowWidth) * 255
        );

        return {
          raw: rawValue,
          windowed: Math.max(0, Math.min(255, windowedValue)),
        };
      },
      [study, selectedSeriesId, currentSlice, windowCenter, windowWidth]
    );

    const handleClearMeasurements = () => {
      onClearMeasurements(); 
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
      const coords = getImageCoordinates(event.clientX, event.clientY);

      // Add bounds checking
      const currentSeries = study.series.find(
        s => getBaseFilename(s.filename) === selectedSeriesId
      );
      if (!currentSeries?.data?.[currentSlice]) return;

      const height = currentSeries.data[currentSlice].length;
      const width = currentSeries.data[currentSlice][0]?.length || 0;

      if (
        coords.x < 0 ||
        coords.x >= width ||
        coords.y < 0 ||
        coords.y >= height
      )
        return;

      // Update HU value display
      const { raw, windowed } = getPixelValue(coords.x, coords.y);
      setMousePosition({
        x: coords.x,
        y: coords.y,
        value: raw,
        windowedValue: windowed,
      });

      // Handle windowing when dragging (always active)
      if (isDragging && lastMousePosition) {
        const deltaX = event.clientX - lastMousePosition.x;
        const deltaY = event.clientY - lastMousePosition.y;

        if (viewerMode !== ViewerMode.Distance) {
          const BRIGHTNESS_SENSITIVITY = 1;
          const CONTRAST_SENSITIVITY = 1;
          const windowMinBound = -1024;
          const windowMaxBound = 3071;
          const windowWidthMin = 1;
          const windowWidthMax = 4096;

          if (Math.abs(deltaY) > Math.abs(deltaX)) {
            setWindowCenter((prev: number) => {
              const newCenter = prev - deltaY * BRIGHTNESS_SENSITIVITY;
              return Math.max(windowMinBound, Math.min(newCenter, windowMaxBound));
            });
          } else {
            setWindowWidth((prev: number) => {
              const newWidth = prev + deltaX * CONTRAST_SENSITIVITY;
              return Math.max(windowWidthMin, Math.min(newWidth, windowWidthMax));
            });
          }
          setLastMousePosition({ x: event.clientX, y: event.clientY });
        }
      }

      // Handle distance measurement
      if (viewerMode === ViewerMode.Distance && activeMeasurement) {
        setActiveMeasurement({
          ...activeMeasurement,
          end: coords,
        });
      }
    };

    const handleMouseLeave = () => {
      setIsDragging(false);
      setLastMousePosition(null);
      if (activeMeasurement?.end) {
        setMeasurements((prev) => [...prev, activeMeasurement]);
        setActiveMeasurement(null);
      }
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
      event.preventDefault();
      const coords = getImageCoordinates(event.clientX, event.clientY);

      if (viewerMode === ViewerMode.Distance) {
        setActiveMeasurement({
          start: coords,
          end: coords,
          pixelSpacing: getPixelSpacing(),
        });
      } else {
        // Default behavior (windowing)
        setIsDragging(true);
        setLastMousePosition({ x: event.clientX, y: event.clientY });
      }
    };

    const handleMouseUp = () => {
      switch (viewerMode) {
        case ViewerMode.Distance:
          if (activeMeasurement?.end) {
            setMeasurements((prev) => [...prev, activeMeasurement]);
            setActiveMeasurement(null);
          }
          break;

        default:
          setIsDragging(false);
          setLastMousePosition(null);
          break;
      }
    };

    useEffect(() => {
      if (!study.isLoading && study.series.length > 0 && selectedSeriesId) {
        renderSlice();
      }
    }, [renderSlice, study.isLoading, study.series.length, selectedSeriesId]);

    useEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas || !study || study.series.length === 0) return;

      const currentSeries = study.series.find(
        s => getBaseFilename(s.filename) === selectedSeriesId
      );
      if (!currentSeries) return;

      const slices = currentSeries.dims[0] || 1;

      const handleWheel = (event: WheelEvent) => {
        event.preventDefault();
        const delta = Math.sign(event.deltaY);
        setCurrentSlice((prev: number) => {
          const newSlice = Math.max(0, Math.min(prev + delta, slices - 1));
          return newSlice;
        });
      };
      

      canvas.addEventListener('wheel', handleWheel, { passive: false });
      return () => canvas.removeEventListener('wheel', handleWheel);
    }, [study, selectedSeriesId, setCurrentSlice]);

    useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.target instanceof HTMLInputElement) return;

        const key = event.key;
        switch (key) {
          case '1':
            handleWindowPreset(WINDOW_PRESETS.SOFT_TISSUE);
            break;
          case '2':
            handleWindowPreset(WINDOW_PRESETS.LUNG);
            break;
          case '3':
            handleWindowPreset(WINDOW_PRESETS.BONE);
            break;
          case '4':
            handleWindowPreset(WINDOW_PRESETS.BRAIN);
            break;
          case '5':
            handleWindowPreset(WINDOW_PRESETS.LIVER);
            break;
          case '?':
            setShowHotkeys((prev) => !prev);
            break;
        }
      };

      window.addEventListener('keydown', handleKeyPress);
      return () => window.removeEventListener('keydown', handleKeyPress);
    }, [handleWindowPreset]);

    // Find the current series
    const currentSeries = study.series.find(
      s => getBaseFilename(s.filename) === selectedSeriesId
    );

    useEffect(() => {
      const currentSeries = study.series.find(
        s => getBaseFilename(s.filename) === selectedSeriesId
      );
      
      if (currentSeries?.data?.[currentSlice]) {
        requestAnimationFrame(() => {
          renderSlice();
        });
      }
    }, [currentSeries?.data, currentSlice, renderSlice, selectedSeriesId, study.series]);

    if (!currentSeries) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="bg-gray-800/50 p-8 rounded-lg border border-gray-700/30">
            <p className="text-lg font-medium text-gray-300">
              Series not found
            </p>
            <p className="text-sm text-gray-400 mt-2">
              The selected series could not be found in the loaded data.
            </p>
          </div>
        </div>
      );
    }

    const ImageOverlay: React.FC = () => (
      <>
        {viewerMetrics.showHUValue && mousePosition && (
          <div className="absolute top-0 left-0 p-2 bg-black bg-opacity-50 text-white text-sm">
            <div>HU Value: {mousePosition.value}</div>
            <div>
              Position: ({mousePosition.x}, {mousePosition.y})
            </div>
            <div>
              Slice: {currentSlice + 1}/{totalSlices}
            </div>
            <div>
              Window: C{windowCenter}/W{windowWidth}
            </div>
          </div>
        )}
      </>
    );


    const HotkeyOverlay: React.FC<{ show: boolean }> = ({ show }) => {
      if (!show) return null;

      return (
        <div className="absolute bottom-4 right-4 bg-black bg-opacity-75 p-4 rounded-lg text-sm">
          <h4 className="font-medium mb-2">Window Presets</h4>
          <div className="space-y-1">
            <div>
              <kbd className="px-2 py-1 bg-gray-700 rounded">1</kbd> Soft Tissue
            </div>
            <div>
              <kbd className="px-2 py-1 bg-gray-700 rounded">2</kbd> Lung
            </div>
            <div>
              <kbd className="px-2 py-1 bg-gray-700 rounded">3</kbd> Bone
            </div>
            <div>
              <kbd className="px-2 py-1 bg-gray-700 rounded">4</kbd> Brain
            </div>
            <div>
              <kbd className="px-2 py-1 bg-gray-700 rounded">5</kbd> Liver
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="relative w-full h-full flex items-center justify-center">
        <canvas
          ref={canvasRef}
          className="max-w-full max-h-full object-contain"
          style={{
            objectFit: 'contain',
            transform: `scale(${viewerMetrics.zoom}) translate(${viewerMetrics.pan.x}px, ${viewerMetrics.pan.y}px)`,
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        />
        <ImageOverlay />
        {viewerMetrics.showCrosshair && mousePosition && (
          <div className="pointer-events-none absolute inset-0">
            <div
              className="absolute w-full h-px bg-yellow-500 opacity-50"
              style={{ top: mousePosition.y }}
            />
            <div
              className="absolute w-px h-full bg-yellow-500 opacity-50"
              style={{ left: mousePosition.x }}
            />
          </div>
        )}
        <HotkeyOverlay show={showHotkeys} />
      </div>
    );
  };

  export default ImageViewer;
