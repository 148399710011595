import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { extractFilenameFromUrl, getBaseFilename } from '../utils/fileHelpers';
import { useSliceAssembler } from './useSliceAssembler';
import { UseSeriesLoaderProps, NpzData} from '../components/types';
import { useWorker } from '../context/WorkerContext';

export function useSeriesLoader({ study, setStudy, userId, npzWorker }: UseSeriesLoaderProps) {
  const [loadingProgress, setLoadingProgress] = useState<Record<string, number>>({});
  const maxProgressRef = useRef<Record<string, number>>({});
  const { handleSliceMessage } = useSliceAssembler();
  const { registerWorker, unregisterWorker } = useWorker();

  // Keep track of active message handlers
  const messageHandlers = useRef<Record<string, (event: MessageEvent) => void>>({});

  // Add a computed value for the currently selected series progress
  const selectedSeriesProgress = useMemo(() => {
    if (!study.selectedSeriesId) return 0;
    return loadingProgress[study.selectedSeriesId] || 0;
  }, [loadingProgress, study.selectedSeriesId]);

  useEffect(() => {
    if (study.modelSelectedSeriesId && !study.selectedSeriesId) {
      setStudy(prev => ({
        ...prev,
        selectedSeriesId: study.modelSelectedSeriesId
      }));
    }
  }, [study.modelSelectedSeriesId]);

  const loadSeries = useCallback(async (seriesUrls: string[]) => {
    const filename = extractFilenameFromUrl(seriesUrls[0]);
    const seriesId = getBaseFilename(filename);
    const workerId = `${study.studyId}-${seriesId}`;
    registerWorker(workerId, npzWorker);
    
  // Only set loading state if not already loading this series
  if (!loadingProgress[seriesId]) {
    setStudy(prev => ({ ...prev, isLoading: true }));
    setLoadingProgress(prev => ({ ...prev, [seriesId]: 0 }));
  }
  
    
    return new Promise<void>((resolve, reject) => {
      const messageHandler = (event: MessageEvent) => {
        const { type, error, seriesId: receivedSeriesId, ...data } = event.data;
        // Verify the message belongs to this series
        if (receivedSeriesId !== seriesId) {
          return; // Ignore messages for other series
        }

        if (type === 'error') {
          console.error(`Error processing NPY files ${filename}: ${error}`);
          setStudy(prev => ({ ...prev, isLoading: false }));
          setLoadingProgress(prev => ({ ...prev, [seriesId]: 0 }));
          delete messageHandlers.current[seriesId];
          reject(error);
          return;
        }

        if (type === 'complete') {
        setLoadingProgress(prev => ({ ...prev, [seriesId]: 100 }));
        // Then clear it completely after a brief delay
        requestAnimationFrame(() => {
          setLoadingProgress({});
        });
        // Clean up the message handler when loading is complete
        setLoadingProgress(prev => {
          const newProgress = { ...prev };
          delete newProgress[seriesId]; // Remove this series from progress tracking
          return newProgress;
        });
        setStudy(prev => ({ ...prev, isLoading: false }));
        delete messageHandlers.current[seriesId];
        resolve();
        return;
        }
  
        if (type === 'slice') {
          handleSliceMessage(
            { ...event.data, seriesId },
            study.studyId,
            (progress) => {
              // Ensure progress never exceeds 100%
              const safeProgress = Math.min(progress, 100);
              // Update max progress in ref
              maxProgressRef.current[seriesId] = Math.max(
                maxProgressRef.current[seriesId] || 0,
                safeProgress
              );
        
              // Use the ref value to ensure we only show increasing progress
              setLoadingProgress(prev => ({
                ...prev,
                [seriesId]: maxProgressRef.current[seriesId]
              }));
        
              // Only clear progress when explicitly complete
              if (safeProgress === 100) {
                setTimeout(() => {
                  setLoadingProgress(prev => {
                    const newProgress = { ...prev };
                    delete newProgress[seriesId];
                    return newProgress;
                  });
                  delete maxProgressRef.current[seriesId];
                }, 200); //200ms delay
              }
            },
            (sliceIndex, sliceData) => {
              // Update study with the new slice immediately
              setStudy(prev => {
                const existingSeries = prev.series.find(
                  s => getBaseFilename(s.filename) === seriesId
                );

                // Ensure metadata is preserved when updating series
                const metadata = prev.metadata?.[seriesId] || {};
                
                if (!existingSeries) {
                  const newData = Array(seriesUrls.length).fill(undefined);
                  newData[sliceIndex] = sliceData;
                  return {
                    ...prev,
                    metadata: {
                      ...prev.metadata,
                      [seriesId]: metadata
                    },
                    isLoading: false, // Remove loading screen after first slice
                    series: [...prev.series, {
                      data: newData,
                      dims: [seriesUrls.length, 512, 512],
                      filename,
                      studyId: study.studyId,
                      predictions: {}
                    }]
                  };
                }
                
                // Update existing series with new slice
                const updatedSeries = [...prev.series];
                const seriesIndex = updatedSeries.findIndex(
                  s => getBaseFilename(s.filename) === seriesId
                );
                
                if (seriesIndex !== -1) {
                  const newData = [...(updatedSeries[seriesIndex].data || [])];
                  newData[sliceIndex] = sliceData;
                  updatedSeries[seriesIndex] = {
                    ...updatedSeries[seriesIndex],
                    data: newData
                  };
                }
                
                // Force a re-render by creating a new reference
                return {
                  ...prev,
                  isLoading: false,
                  series: updatedSeries
                };
              });
            },
            (npzData) => resolve()
          );
        }
      };
  
      // Store the message handler for this series
      messageHandlers.current[seriesId] = messageHandler;

      // Add the message handler to the worker
      npzWorker.addEventListener('message', messageHandler);

      npzWorker.postMessage({
        filename,
        seriesId, // Add seriesId to the worker message
        downloadUrls: seriesUrls,
        user_id: userId,
        study_id: study.studyId,
      });
    }).finally(() => {
      // Clean up the message handler when the promise resolves or rejects
      if (messageHandlers.current[seriesId]) {
        npzWorker.removeEventListener('message', messageHandlers.current[seriesId]);
        delete messageHandlers.current[seriesId];
        unregisterWorker(`${study.studyId}-${seriesId}`);
      }
    });
  }, [npzWorker, userId, setStudy, study.studyId, handleSliceMessage]);

    // Clean up all message handlers when the component unmounts
    useEffect(() => {
      return () => {
        Object.entries(messageHandlers.current).forEach(([_, handler]) => {
          npzWorker.removeEventListener('message', handler);
        });
        messageHandlers.current = {};
      };
    }, [npzWorker]);

    const handleSeriesSelect = useCallback(async (seriesId: string) => {
      if (!seriesId) return;
    
      const seriesUrls = study.seriesUrls[seriesId];
      if (!seriesUrls?.length) return;
    
      const isLoaded = study.series.some(
        s => getBaseFilename(s.filename) === seriesId
      );
  
    
      setStudy(prev => ({
        ...prev,
        selectedSeriesId: seriesId
      }));
    
      if (!isLoaded && !study.isLoading) {
        await loadSeries(seriesUrls);
      }
    }, [study.series, study.selectedSeriesId, study.isLoading, study.seriesUrls, loadSeries, setStudy]);

    const initialLoadRef = useRef(false);

    useEffect(() => {
      const shouldLoadInitialSeries = 
        !initialLoadRef.current &&
        study.modelSelectedSeriesId && 
        Object.keys(study.seriesUrls).length > 0 && 
        study.series.length === 0 &&
        !study.isLoading;
    
      if (!shouldLoadInitialSeries) return;
    
      const selectedId = study.modelSelectedSeriesId;
      if (!selectedId) return;
    
      const urls = study.seriesUrls[selectedId];
      if (!urls?.length) return;
  
      initialLoadRef.current = true;
      
      // Use requestAnimationFrame to break potential update cycles
      requestAnimationFrame(() => {
        loadSeries(urls).then(() => {
          // After loading, set this as the selected series
          setStudy(prev => ({
            ...prev,
            selectedSeriesId: selectedId
          }));
        });
      });
    }, [study.modelSelectedSeriesId, study.seriesUrls, study.series.length, study.isLoading, loadSeries, handleSeriesSelect]);

  const setSliceIndex = useCallback((slice: number, totalSlices: number) => {
    return Math.max(0, Math.min(slice, totalSlices - 1));
  }, []);

  return {
    selectedSeriesId: study.selectedSeriesId,
    handleSeriesSelect,
    loadingProgress: selectedSeriesProgress,
    isLoading: study.isLoading,
    setSliceIndex
  };
}