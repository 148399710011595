import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/auth';
import { isTokenExpired } from '../utils/tokenUtils'
import axios from 'axios';

interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  refreshToken?: string | null;
  userId: string | null;
  isAdmin: boolean;
  adminUserId?: string | null;
}


interface AuthContextType extends AuthState {
  login: (userId: string, password: string) => Promise<void>;
  logout: () => void;
  setAuthState: (state: AuthState) => void;  // Add this line
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>(() => {
    const {token, refreshToken, userId, isAdmin, adminUserId } = authService.getStoredAuth();
    return {
      isAuthenticated: Boolean(token && userId),
      token,
      refreshToken,
      userId,
      isAdmin: Boolean(isAdmin), 
      adminUserId
    };
  });
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    if (authState.userId) {
      await authService.logout(authState.userId);
    }
    setAuthState({
      isAuthenticated: false,
      token: null,
      userId: null, 
      isAdmin: false, 
      adminUserId: null
    });
    navigate('/login');
  }, [authState.userId]);

  // Separate effect for token refresh interval
  useEffect(() => {
    let refreshInterval: NodeJS.Timeout;

    const scheduleTokenRefresh = () => {
      if (!authState.token || !authState.refreshToken) return;

      // Clear any existing interval
      if (refreshInterval) clearInterval(refreshInterval);

      // Check token every minute
      refreshInterval = setInterval(async () => {
        if (isTokenExpired(authState.token!)) {
          try {
            const newToken = await authService.refreshToken();
            if (newToken) {
              setAuthState(prev => ({
                ...prev,
                token: newToken
              }));
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Background token refresh failed:', error);
            handleLogout();
          }
        }
      }, 3600000); // Check every minute
    };

    scheduleTokenRefresh();

    return () => {
      if (refreshInterval) clearInterval(refreshInterval);
    };
  }, [authState.token, authState.refreshToken, handleLogout]);

  // Separate effect for axios interceptors
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use((config) => {
      const { token } = authService.getStoredAuth();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const newToken = await authService.refreshToken();
            if (newToken) {
              setAuthState(prev => ({
                ...prev,
                token: newToken
              }));

              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              return axios(originalRequest);
            }
          } catch (refreshError) {
            handleLogout();
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [handleLogout]);

  const login = useCallback(async (userId: string, password: string) => {
    try {
      const response = await authService.login(userId, password);
      const isAdmin = response.availableUserIds.length > 1;
      setAuthState({
        isAuthenticated: true,
        token: response.token,
        userId,
        isAdmin,
        adminUserId: isAdmin ? userId : null
      });
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }, []);


  const value = useMemo(
    () => ({
      ...authState,
      login,
      logout: handleLogout,
      setAuthState, 
    }),
    [authState, login, handleLogout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
