import React from 'react';
import { useAuth } from '../context/AuthContext';
import { handleCSVDownload } from '../utils/csvDownload';

export const DownloadCSV: React.FC = () => {
  const { token, userId } = useAuth();

  React.useEffect(() => {
    const downloadCSVs = async () => {
      if (!token || !userId) return;
      
      try {
        await handleCSVDownload(userId, token);
      } catch (error) {
        console.error('Error downloading CSV:', error);
      }
    };

    downloadCSVs();
  }, [userId, token]);

  return null;
};