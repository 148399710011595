import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { authService } from '../services/auth';
import { Location, useLocation } from 'react-router-dom';

// Add this interface to define the expected state shape
interface LocationState {
  fromSwitch?: boolean;
  token?: string;
  refreshToken?: string;
  availableUserIds?: string[];
  adminUserId?: string;
}

interface UserSelectorProps {
  availableUserIds: string[];
  token: string;
  refreshToken: string; 
  adminUserId?: string;
}

const UserSelector: React.FC<UserSelectorProps> = ({ availableUserIds, token, refreshToken, adminUserId }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation() as { state: LocationState };  // Type assertion here
  const isAdmin = availableUserIds.length > 1;

  useEffect(() => {
    // Only redirect if not coming from switch user
    if (!auth.isAuthenticated && !location.state?.fromSwitch) {
      navigate('/login');
    }
  }, [auth.isAuthenticated, navigate, location.state]);

  const handleUserSelect = (selectedUserId: string) => {
  
    // Set the auth cookies with both tokens
    authService.setAuthCookies(
      token,
      refreshToken,
      selectedUserId,
      isAdmin,
      adminUserId
    );
    
    // Update auth context
    auth.setAuthState({
      isAuthenticated: true,
      token,
      refreshToken,
      userId: selectedUserId, 
      isAdmin,
      adminUserId: isAdmin ? adminUserId : null
    });
  
    navigate('/worklist');
  };
  return (
    <div className="flex min-h-screen bg-gray-900 text-white">
      <div className="flex-1 flex items-center justify-center p-4">
        <div className="w-full max-w-md bg-gray-800/50 backdrop-blur-md border border-gray-700/50 rounded-lg p-8 shadow-2xl">
        <div className="text-center mb-8">
            <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent">
              a2z
            </span>
            <h2 className="mt-4 text-xl font-medium text-gray-300">Select User Account</h2>
          </div>
          
          <div className="space-y-4 max-h-[60vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 pr-2">
            {availableUserIds
              .sort((a, b) => a.localeCompare(b))
              .map((userId) => (
              <button
                key={userId}
                onClick={() => handleUserSelect(userId)}
                className="w-full bg-gray-700/50 hover:bg-gray-700/70 
                  text-white font-medium py-3 px-4 rounded-lg
                  transition-colors duration-200 text-left"
              >
                {userId}
              </button>
            ))}
            <button
              onClick={() => {
                // Update auth context first
                auth.setAuthState({
                  isAuthenticated: true,
                  token,
                  refreshToken: authService.getStoredAuth().refreshToken,
                  userId: adminUserId || null,
                  isAdmin: true,
                  adminUserId
                });
                
                // Then navigate
                navigate('/add-user', { 
                  state: { 
                    token,
                    refreshToken, 
                    adminUserId,
                    fromSwitch: true 
                  }
                });
              }}
              className="w-full bg-gray-700/50 hover:bg-gray-700/70 
                text-white font-medium py-3 px-4 rounded-lg
                transition-colors duration-200 text-left
                border border-primary/30 mt-4"
            >
              + Add User
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSelector;