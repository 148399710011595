export const extractFilenameFromUrl = (url: string): string => {
  // Extract the NPZ filename from the full URL, including slice prefix
  const match = url.match(/\/(?:SLICE_\d+_)?NPZ_([^?]+)\.npz/);
  if (match) {
    return `NPZ_${match[1]}.npz`;
  }
  return url;
};

export const getBaseFilename = (filename: string): string => {
  // Remove slice prefix if present and extract the study ID
  const cleanName = filename.replace(/^SLICE_\d+_/, '');
  const match = cleanName.match(/NPZ_(.+?)\.npz/);
  if (!match) return filename || '';
  return match[1];
};
