import { useEffect, useRef, useState } from 'react';

export function useInView(options = {}) {
  const [isInView, setIsInView] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const elementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
      
      // If element comes into view and hasn't been loaded yet, mark it as loaded
      if (entry.isIntersecting && !hasLoaded) {
        setHasLoaded(true);
      }
    }, options);

    observer.observe(element);

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [hasLoaded, options]);

  return { elementRef, isInView, hasLoaded };
}