import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config/api';
import { useAuth } from './AuthContext';

interface OpenAIConfig {
  systemPrompt: string;
  model: string;
  temperature: number; 
  responseFormat: {
    type: string;
    json_schema: {
      name: string;
      schema: {
        type: string;
        properties: {
          findings: {
            type: string;
            items: {
              type: string;
              properties: {
                group_id: { type: string };
                text: { type: string };
              };
              required: string[];
              additionalProperties: boolean;
            };
          };
        };
        required: string[];
        additionalProperties: boolean;
      };
      strict: boolean;
    };
  };
}

interface OpenAIConfigPayload {
  user_id: string;
  prompt: string;
  config: {
    model: string;
    temperature: number;
    responseFormat: any;
  };
  name: string;
  is_prod: boolean;
}

interface OpenAIConfigContextType {
  config: OpenAIConfig;
  updateConfig: (newConfig: Partial<OpenAIConfig>) => void;
  availableConfigs: OpenAIConfig[];
  availablePrompts: string[];
  configNames: string[];
  selectedConfigName: string;
  setSelectedConfigName: (name: string) => void;
  saveConfig: (configName: string, isProd: boolean, newConfig?: OpenAIConfig) => Promise<void>;
  prodConfigName: string;
}

const defaultConfig: OpenAIConfig = {
  systemPrompt: "",
  model: "gpt-4o",
  temperature: 0.3,
  responseFormat: {
    type: "json_schema",
    json_schema: {
      name: "report_response",
      schema: {
        type: "object",
        properties: {
          findings: {
            type: "array",
            items: {
              type: "object",
              properties: {
                group_id: { type: "string" },
                text: { type: "string" }
              },
              required: ["group_id", "text"],
              additionalProperties: false
            }
          }
        },
        required: ["findings"],
        additionalProperties: false
      },
      strict: true
    }
  }
};

const OpenAIConfigContext = createContext<OpenAIConfigContextType>({
  config: defaultConfig,
  updateConfig: () => {},
  availableConfigs: [],
  availablePrompts: [],
  configNames: [],
  selectedConfigName: '',
  setSelectedConfigName: () => {},
  saveConfig: async () => {},
  prodConfigName: '',
});

export const OpenAIConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [config, setConfig] = useState<OpenAIConfig>(defaultConfig);
  const [availableConfigs, setAvailableConfigs] = useState<OpenAIConfig[]>([]);
  const [availablePrompts, setAvailablePrompts] = useState<string[]>([]);
  const [configNames, setConfigNames] = useState<string[]>([]);
  const [selectedConfigName, setSelectedConfigName] = useState<string>('');
  const [prodConfigName, setProdConfigName] = useState<string>('');
  const { userId, token, isAdmin, adminUserId } = useAuth();

  const fetchConfigs = async () => {
    // If not authenticated at all, return early
    if (!token || !userId || !isAdmin || !adminUserId) {
      return;
    }

  
    try {
      const response = await axios.post(
        `${API_BASE_URL}/get_openai_config`,
        { user_id: adminUserId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
  
      if (response.data.status === 200) {
        const { prompts, configs, names, prod_name } = response.data;
        
        // Create complete configs by combining prompts with their corresponding configs
        const completeConfigs = configs.map((config: any, index: number) => ({
          ...config,
          systemPrompt: prompts[index]
        }));
  
        setAvailableConfigs(completeConfigs);
        setAvailablePrompts(prompts);
        setConfigNames(names);
        setProdConfigName(prod_name || '');
  
        // Set the initial selected config to the production config if available
        if (prod_name && names.includes(prod_name)) {
          const prodIndex = names.indexOf(prod_name);
          setSelectedConfigName(prod_name);
          setConfig(completeConfigs[prodIndex]);
        } else if (names.length > 0) {
          setSelectedConfigName(names[0]);
          setConfig(completeConfigs[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching OpenAI configs:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
      }
    }
  };
  
  // Update useEffect to include all necessary dependencies
  useEffect(() => {
    if (isAdmin) {
      fetchConfigs();
    } else {
      // For non-admin users, just use the default config
      setConfig(defaultConfig);
      setAvailableConfigs([defaultConfig]);
      setConfigNames(['default']);
      setSelectedConfigName('default');
    }
  }, [token, userId, isAdmin, adminUserId]);

  const updateConfig = (newConfig: Partial<OpenAIConfig>) => {
    setConfig(prev => ({ ...prev, ...newConfig }));
  };

  const saveConfig = async (configName: string, isProd: boolean, newConfig?: OpenAIConfig) => {
    if (!isAdmin || !token || !adminUserId) {
      console.error('No authentication available');
      return;
    }

    try {
      // Use newConfig if provided, otherwise use current config
      const configToSave = newConfig || config;

      const payload: OpenAIConfigPayload = {
        user_id: adminUserId,
        prompt: configToSave.systemPrompt,
        config: {
          model: configToSave.model,
          temperature: configToSave.temperature,
          responseFormat: configToSave.responseFormat
        },
        name: configName,
        is_prod: configName === prodConfigName || isProd
      };

      const response = await axios.post(
        `${API_BASE_URL}/add_openai_config`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data.status === 200) {
        // Update prod config name if necessary
        if (isProd) {
          setProdConfigName(configName);
        }
        
        // Refresh the configs
        await fetchConfigs();
      } else {
        throw new Error(response.data.message || 'Failed to update config');
      }
    } catch (error) {
      console.error('Error saving OpenAI config:', error);
      throw error;
    }
  };

  return (
    <OpenAIConfigContext.Provider value={{
      config,
      updateConfig,
      availableConfigs,
      availablePrompts,
      configNames,
      selectedConfigName,
      setSelectedConfigName,
      saveConfig,
      prodConfigName,
    }}>
      {children}
    </OpenAIConfigContext.Provider>
  );
};

export const useOpenAIConfig = () => useContext(OpenAIConfigContext);