import React, { createContext, useContext, useState } from 'react';

export interface BoundingBox {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

export interface SliceBoundingBox {
  slice_idx: number;
  slice_importance: number;
  bbox: BoundingBox;
}

export interface BoundingBoxData {
  slices: SliceBoundingBox[];
}

export interface PredictionsContextType {
  predictions: Record<string, any>;
  setPredictions: (predictions: Record<string, any>) => void;
  activeModel: 'base' | 'experimental';
  setActiveModel: (model: 'base' | 'experimental') => void;
  selectedFinding: string | null;
  setSelectedFinding: (findingId: string | null) => void;
  baseModelPredictions: Record<string, any>;
  setBaseModelPredictions: (predictions: Record<string, any>) => void;
  experimentalModelPredictions: Record<string, any>;
  setExperimentalModelPredictions: (predictions: Record<string, any>) => void;
}

const defaultContext: PredictionsContextType = {
  predictions: {},
  setPredictions: () => {},
  activeModel: 'base',
  setActiveModel: () => {},
  selectedFinding: null,
  setSelectedFinding: () => {},
  baseModelPredictions: {},
  setBaseModelPredictions: () => {},
  experimentalModelPredictions: {},
  setExperimentalModelPredictions: () => {}
};

const PredictionsContext = createContext<PredictionsContextType>(defaultContext);

export const PredictionsProvider: React.FC<{
  children: React.ReactNode;
  initialPredictions?: Record<string, any>;
  initialModel?: 'base' | 'experimental';
}> = ({ children, initialPredictions = {}, initialModel = 'base' }) => {
  const [predictions, setPredictions] = useState<Record<string, any>>(initialPredictions);
  const [activeModel, setActiveModel] = useState<'base' | 'experimental'>(initialModel);
  const [selectedFinding, setSelectedFinding] = useState<string | null>(null);
  const [baseModelPredictions, setBaseModelPredictions] = useState<Record<string, any>>({});
  const [experimentalModelPredictions, setExperimentalModelPredictions] = useState<Record<string, any>>({});

  return (
    <PredictionsContext.Provider value={{
      predictions,
      setPredictions,
      activeModel,
      setActiveModel,
      selectedFinding,
      setSelectedFinding,
      baseModelPredictions,
      setBaseModelPredictions,
      experimentalModelPredictions,
      setExperimentalModelPredictions
    }}>
      {children}
    </PredictionsContext.Provider>
  );
};

export const usePredictions = () => {
  const context = useContext(PredictionsContext);
  return context;
};