// src/components/ViewerControls.tsx

import React, { useState } from 'react';
import { FaLayerGroup, FaRuler, FaCog, FaEraser, FaDatabase, FaLightbulb, FaWindowMaximize } from 'react-icons/fa';
import { formatText } from '../utils/textFormatters';
import { ViewerMetrics, ViewerMode, StudyData, NpzData } from './types'; // Import from types
import { extractFilenameFromUrl, getBaseFilename } from '../utils/fileHelpers'; // Import from fileHelpers

const WINDOW_PRESETS = {
  SOFT_TISSUE: { center: 40, width: 400 }, // Hotkey: 1
  LUNG: { center: -600, width: 1500 }, // Hotkey: 2
  BONE: { center: 400, width: 1800 }, // Hotkey: 3
  BRAIN: { center: 40, width: 80 }, // Hotkey: 4
  LIVER: { center: 30, width: 150 }, // Hotkey: 5
} as const;

interface ViewerControlsProps {
  series: NpzData[];
  selectedSeriesId: string | undefined;
  onSelect: (seriesUrl: string) => void;
  studyId: string;
  allSeriesUrls: Record<string, string[]>; 
  windowCenter: number;
  windowWidth: number;
  onWindowingChange: (center: number, width: number) => void;
  viewerMode: ViewerMode;
  onViewerModeChange: (mode: ViewerMode) => void;
  measurements: any[];
  onClearMeasurements: () => void;
  onWindowPresetChange: (preset: { center: number; width: number }) => void;
  viewerMetrics: ViewerMetrics;
  setViewerMetrics: React.Dispatch<React.SetStateAction<ViewerMetrics>>;
  study: StudyData;
  onLoadSeries: (seriesUrl: string) => void;
  currentSlice: number;
  totalSlices: number;
  onSliceChange: (slice: number) => void;
  modelSelectedSeriesId?: string;
}

interface MetadataState {
  searchTerm: string;
  expandedKeys: Set<string>;
  sortBy: 'key' | 'value';
  sortDirection: 'asc' | 'desc';
}

interface SeriesSelectorProps {
  series: NpzData[];
  selectedSeriesId: string | undefined;
  onSelect: (seriesUrl: string) => void;
  studyId: string;
  allSeriesUrls: Record<string, string[]>; 
  study: StudyData;
  modelSelectedSeriesId?: string;
}

const SeriesSelector: React.FC<SeriesSelectorProps> = ({
  series,
  selectedSeriesId,
  onSelect,
  studyId,
  allSeriesUrls,
  study,
  modelSelectedSeriesId,
}) => {
  return (
    <div className="space-y-2">
      {Object.entries(allSeriesUrls).map(([seriesId, urls], index) => {
        const isLoaded = study.series.some(
          (s) => getBaseFilename(s.filename) === seriesId
        );
        const isSelected = selectedSeriesId === seriesId;
        const isDefaultSeries = study.selectedSeriesId === seriesId;

        return (
          <button
            key={seriesId}
            onClick={() => onSelect(seriesId)}
            className={`
              w-full text-left p-2 rounded-lg flex items-center justify-between group
              transition-all duration-200 ease-in-out
              ${
                isSelected
                  ? 'bg-gradient-to-r from-primary/10 to-primary/20 border-primary/30 border'
                  : 'bg-gray-800/30 hover:bg-gray-700/50 border border-transparent'
              }
              ${isDefaultSeries ? 'ring-1 ring-primary/30' : ''}
              ${isDefaultSeries ? 'ring-1 ring-primary/50' : ''}
            `}
          >
            <div className="flex items-center space-x-2 w-full min-w-0">
              <span className="text-sm flex-1 truncate">
                <span className="opacity-50 mr-1">#{index + 1}</span>
                {seriesId}
                {isDefaultSeries && (
                  <span className="ml-1 text-xs text-primary-light">(Default)</span>
                )}
                {isDefaultSeries && (
                  <span className="ml-1 text-xs text-primary-light">(Model Series)</span>
                )}
              </span>
              <span
                className={`text-xs px-2 py-0.5 rounded-full flex-shrink-0
                  ${
                    isLoaded
                      ? 'bg-primary/20 text-primary-light'
                      : 'bg-gray-700/50 text-gray-400'
                  }
                  transition-all duration-200
                `}
              >
                {isLoaded ? 'Loaded' : 'Not Loaded'}
              </span>
            </div>
          </button>
        );
      })}
    </div>
  );
};

const ViewerControls: React.FC<ViewerControlsProps> = ({
  series,
  selectedSeriesId,
  onSelect,
  studyId,
  allSeriesUrls,
  windowCenter,
  windowWidth,
  onWindowingChange,
  viewerMode,
  onViewerModeChange,
  measurements,
  onClearMeasurements,
  onWindowPresetChange,
  viewerMetrics,
  setViewerMetrics,
  study,
  onLoadSeries,
  currentSlice,
  totalSlices,
  onSliceChange,
  modelSelectedSeriesId,
}) => {
  const [expandedPanel, setExpandedPanel] = useState<
    'series' | 'tools' | 'settings' | null
  >('series');
  const [isInfoExpanded, setIsInfoExpanded] = useState(false);
  const [isMetadataExpanded, setIsMetadataExpanded] = useState(false);
  const [metadataState, setMetadataState] = useState({
    searchTerm: '',
    expandedKeys: new Set<string>()
  });

  const updateViewerMetrics = (updates: Partial<ViewerMetrics>) => {
    setViewerMetrics(prev => ({
      ...prev,
      ...updates
    }));
  };

  return (
    <div className="space-y-2">
      {/* Series Selection Panel */}
      <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg overflow-hidden">
        <div
          className="p-2 bg-gray-800/50 border-b border-gray-700/50 flex items-center justify-between cursor-pointer"
          onClick={() =>
            setExpandedPanel(expandedPanel === 'series' ? null : 'series')
          }
        >
          <h3 className="text-xs font-medium flex items-center text-gray-300">
            <FaLayerGroup className="mr-2" />
            Series
          </h3>
          <span className="text-[11px] text-gray-400">
            {allSeriesUrls.length} series
          </span>
        </div>
        <div
          className={`transition-all duration-300 ${
            expandedPanel === 'series' ? 'max-h-[calc(100vh-400px)]' : 'max-h-0'
          } overflow-hidden`}
        >
          <div className="p-2">
            <SeriesSelector
              series={series}
              selectedSeriesId={selectedSeriesId}
              onSelect={onSelect}
              studyId={studyId}
              allSeriesUrls={allSeriesUrls}
              study={study}
              modelSelectedSeriesId={modelSelectedSeriesId}
            />
          </div>
        </div>
      </div>

      {/* Slice Navigation Panel */}
      <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg p-3">
        <div className="flex flex-col items-center space-y-3">
          {/* Slice Counter with improved styling */}
          <div className="flex items-center justify-between w-full bg-gray-800/30 rounded-md p-2">
            <span className="text-xs font-medium text-gray-400">
              Current Position
            </span>
            <div className="flex items-center space-x-2">
              <span className="text-sm font-semibold text-white">
                {currentSlice + 1}
              </span>
              <span className="text-xs text-gray-500">/</span>
              <span className="text-xs text-gray-400">{totalSlices}</span>
            </div>
          </div>

          {/* Slice Navigation Controls */}
          <div className="flex items-center space-x-4 w-full">
            <div className="relative flex-1 bg-gray-800/30 rounded-lg p-2">
              <div className="flex items-center justify-between space-x-4">
                {/* Slice Input */}
                <div className="flex-1">
                  <label className="text-xs font-medium text-gray-400 mb-1 block">
                    Slice Number
                  </label>
                  <input
                    type="number"
                    min={1}
                    max={totalSlices}
                    value={currentSlice + 1}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      if (!isNaN(value) && value >= 1 && value <= totalSlices) {
                        onSliceChange(value - 1);
                      }
                    }}
                    className="w-full bg-gray-900/50 border border-gray-700 rounded px-2 py-1
                          text-sm text-white focus:outline-none focus:border-primary
                          focus:ring-1 focus:ring-primary transition-colors"
                  />
                </div>

                {/* Total Slices Display */}
                <div className="flex flex-col items-end justify-center">
                  <span className="text-xs text-gray-400">Total Slices</span>
                  <span className="text-sm font-medium text-gray-300">
                    {totalSlices}
                  </span>
                </div>
              </div>

              {/* Progress Bar */}
              <div className="mt-2 h-1 bg-gray-800 rounded-full overflow-hidden">
                <div
                  className="h-full bg-primary/30 transition-all duration-200"
                  style={{
                    width: `${((currentSlice + 1) / totalSlices) * 100}%`,
                  }}
                />
              </div>
            </div>

            {/* Quick Navigation Buttons */}
            <div className="flex flex-col space-y-2">
              <button
                onClick={() =>
                  onSliceChange(Math.min(currentSlice + 10, totalSlices - 1))
                }
                className="p-1.5 rounded-md bg-gray-800/30 hover:bg-gray-700/30 text-gray-400 hover:text-primary
                      transition-all duration-200 border border-gray-800
                      active:transform active:scale-95"
                title="Forward 10 slices"
              >
                <svg
                  className="w-3 h-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 11l7-7m0 0l7 7m-7-7v18"
                  />
                </svg>
              </button>
              <button
                onClick={() =>
                  onSliceChange(Math.max(currentSlice - 10, 0))
                }
                className="p-1.5 rounded-md bg-gray-800/30 hover:bg-gray-700/30 text-gray-400 hover:text-primary
                      transition-all duration-200 border border-gray-800
                      active:transform active:scale-95"
                title="Back 10 slices"
              >
                <svg
                  className="w-3 h-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Jump to Position Buttons - more minimal */}
          <div className="flex justify-between w-full space-x-1">
            <button
              onClick={() => onSliceChange(0)}
              className="flex-1 px-2 py-1 text-[10px] font-medium rounded bg-gray-800/30 hover:bg-gray-700/30
                    text-gray-400 hover:text-primary transition-all duration-200 border border-gray-800"
            >
              First
            </button>
            <button
              onClick={() => onSliceChange(Math.floor(totalSlices / 2))}
              className="flex-1 px-2 py-1 text-[10px] font-medium rounded bg-gray-800/30 hover:bg-gray-700/30
                    text-gray-400 hover:text-primary transition-all duration-200 border border-gray-800"
            >
              Middle
            </button>
            <button
              onClick={() => onSliceChange(totalSlices - 1)}
              className="flex-1 px-2 py-1 text-[10px] font-medium rounded bg-gray-800/30 hover:bg-gray-700/30
                    text-gray-400 hover:text-primary transition-all duration-200 border border-gray-800"
            >
              Last
            </button>
          </div>
        </div>
      </div>

    {/* After the slice selection controls */}
    <div className="space-y-2 pt-2 border-t border-gray-700/50">
      {/* Metadata Panel */}
      <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg overflow-hidden">
        <div
          className="p-2 bg-gray-800/50 border-b border-gray-700/50 flex items-center justify-between cursor-pointer"
          onClick={() => setIsMetadataExpanded(!isMetadataExpanded)}
        >
          <h3 className="text-xs font-medium flex items-center text-gray-300">
            <FaDatabase className="mr-2" />
            DICOM Metadata
          </h3>
        </div>
        <div
          className={`transition-all duration-300 ${
            isMetadataExpanded ? 'max-h-[calc(100vh-200px)]' : 'max-h-0'
          } overflow-hidden`}
        >
          <div className="p-2">
          <div className="text-xs text-gray-400 space-y-2 overflow-y-auto max-h-[calc(100vh-250px)]">
            {selectedSeriesId && study.metadata ? (
              Object.entries(study.metadata[selectedSeriesId] || {})
              .filter(([key]) => key !== 'Error' && key !== 'InstitutionName') // Filter out error and institution fields
              .map(([key, value]) => (
                <div key={key} className="flex flex-col">
                  <div 
                    className="flex items-center justify-between cursor-pointer hover:bg-gray-800/30 p-1 rounded"
                    onClick={() => {
                      setMetadataState(prev => ({
                        ...prev,
                        expandedKeys: new Set(
                          prev.expandedKeys.has(key) 
                            ? Array.from(prev.expandedKeys).filter(k => k !== key)
                            : Array.from(prev.expandedKeys).concat([key])
                        )
                      }));
                    }}
                  >
                    <span className="font-medium text-gray-300">{key}</span>
                    {typeof value === 'object' && value !== null && (
                      <FaCog 
                        className={`w-3 h-3 text-gray-500 transition-transform duration-200 ${
                          metadataState.expandedKeys.has(key) ? 'rotate-180' : ''
                        }`}
                      />
                    )}
                  </div>
                  <div className={`
                    mt-1 bg-gray-800/30 px-2 py-1 rounded break-all font-mono text-gray-300
                    ${typeof value === 'object' && value !== null && !metadataState.expandedKeys.has(key) ? 'hidden' : ''}
                  `}>
                    {value === null ? (
                      <span className="text-gray-500 italic">null</span>
                    ) : typeof value === 'object' ? (
                      <pre className="whitespace-pre-wrap text-xs">{JSON.stringify(value, null, 2)}</pre>
                    ) : value === '' ? (
                      <span className="text-gray-500 italic">empty string</span>
                    ) : (
                      String(value)
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-gray-500 italic">
                No metadata available for this series
              </div>
            )}
          </div>
            </div>
          </div>
        </div>


        {/* Study and Series Info Collapsible Panel */}
        <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg overflow-hidden">
          <div
            className="p-2 bg-gray-800/50 border-b border-gray-700/50 flex items-center justify-between cursor-pointer"
            onClick={() => setIsInfoExpanded(!isInfoExpanded)}
          >
            <h3 className="text-xs font-medium flex items-center text-gray-300">
              <FaLayerGroup className="mr-2" />
              Study & Series Info
            </h3>
          </div>
          <div
            className={`transition-all duration-300 ${
              isInfoExpanded ? 'max-h-40' : 'max-h-0'
            } overflow-hidden`}
          >
            <div className="p-2">
              <div className="text-xs text-gray-400 space-y-2">
                <div className="flex flex-col">
                  <span className="mb-1">Study ID:</span>
                  <div className="bg-gray-800/30 px-2 py-1 rounded break-all font-mono text-gray-300">
                    {studyId}
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="mb-1">Selected Series:</span>
                  <div
                    className="bg-gray-800/30 px-2 py-1 rounded break-all font-mono text-gray-300"
                    title={selectedSeriesId}
                  >
                    {selectedSeriesId ? getBaseFilename(selectedSeriesId) : 'None'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* Quick Tools Bar */}
      <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg p-2">
        <div className="flex items-center space-x-2">
        <button
            onClick={() => updateViewerMetrics({ showHUValue: !viewerMetrics.showHUValue })}
            className={`p-1.5 rounded-md text-sm transition-all duration-200 ${
              viewerMetrics.showHUValue
                ? 'bg-primary text-white shadow-lg shadow-primary/20'
                : 'text-gray-400 hover:text-white hover:bg-gray-800/50 border border-gray-700/30'
            }`}
            title="Show HU Values"
          >
            <FaLightbulb size={14} />
          </button>
        <button
              onClick={() => {
                const newMode = viewerMode === ViewerMode.Distance ? ViewerMode.Default : ViewerMode.Distance;
                onViewerModeChange(newMode);
                setViewerMetrics(prev => ({
                  ...prev,
                  showMeasurements: newMode === ViewerMode.Distance
                }));
              }}
              className={`p-1.5 rounded-md text-sm transition-all duration-200 ${
                viewerMode === ViewerMode.Distance
                  ? 'bg-primary text-white shadow-lg shadow-primary/20'
                  : 'text-gray-400 hover:text-white hover:bg-gray-800/50 border border-gray-700/30'
              }`}
              title="Measure Distance"
            >
              <FaRuler size={14} />
            </button>
              {/* Eraser button - only visible when measurement mode is active */}
              {viewerMode === ViewerMode.Distance && (
                <button
                  onClick={onClearMeasurements}  // Connect the handler
                  className={`p-2 rounded hover:bg-gray-700/50 transition-colors ${
                    viewerMode === ViewerMode.Distance ? 'text-primary' : 'text-gray-400'
                  }`}
                  title="Clear measurements"
                >
                  <FaEraser className="w-4 h-4" />
                </button>
              )}
              

        </div>
      </div>

      {/* Window Presets Bar */}
      <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg p-2">
        <div className="flex flex-wrap gap-1.5">
          {Object.entries(WINDOW_PRESETS).map(([name, preset], index) => (
            <button
              key={name}
              onClick={() => onWindowPresetChange(preset)}
              className="px-2.5 py-1 text-gray-400 hover:text-white hover:bg-gray-800/50
                    rounded-md text-xs transition-all duration-200 font-medium
                    border border-gray-700/30"
            >
              <span className="opacity-50 mr-1">{index + 1}</span>
              {formatText(name)}
            </button>
          ))}
        </div>
      </div>

      {/* Advanced Settings Panel */}
      <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg overflow-hidden">
        <div
          className="p-2 bg-gray-800/50 border-b border-gray-700/50 flex items-center justify-between cursor-pointer"
          onClick={() =>
            setExpandedPanel(expandedPanel === 'settings' ? null : 'settings')
          }
        >
          <h3 className="text-xs font-medium flex items-center text-gray-300">
            <FaWindowMaximize className="mr-2" />
            Window Settings
          </h3>
        </div>
        <div
          className={`transition-all duration-300 ${
            expandedPanel === 'settings' ? 'max-h-96' : 'max-h-0'
          } overflow-hidden`}
        >
          <div className="p-3 space-y-4">
            {/* Windowing Controls */}
            <div className="space-y-4">
              <div>
                <div className="flex justify-between mb-1">
                  <label className="text-xs font-medium text-gray-400">
                    Window Center
                  </label>
                  <span className="text-xs text-gray-400">
                    {windowCenter}
                  </span>
                </div>
                <input
                  type="range"
                  min={-1024}
                  max={3071}
                  value={windowCenter}
                  onChange={(e) =>
                    onWindowingChange(
                      Number(e.target.value),
                      windowWidth
                    )
                  }
                  className="w-full accent-primary"
                />
              </div>
              <div>
                <div className="flex justify-between mb-1">
                  <label className="text-xs font-medium text-gray-400">
                    Window Width
                  </label>
                  <span className="text-xs text-gray-400">
                    {windowWidth}
                  </span>
                </div>
                <input
                  type="range"
                  min={1}
                  max={4096}
                  value={windowWidth}
                  onChange={(e) =>
                    onWindowingChange(
                      windowCenter,
                      Number(e.target.value)
                    )
                  }
                  className="w-full accent-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewerControls;
