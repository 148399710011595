export interface BBoxSummary {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
  center: {
    x: number;
    y: number;
    z: number;
  };
  sliceRange: {
    start: number;
    end: number;
    bestSlice: {
      idx: number;
      importance: number;
    };
  };
}

export interface Finding {
  condition: string;
  status: 'detected' | 'suspected';
  probability: number;
  bboxSummary: BBoxSummary | null;
}

const bboxCache = new Map<string, BBoxSummary | null>();

export const getBBoxSummary = (bboxData: any): BBoxSummary | null => {
  // Generate cache key from the stringified data
  const cacheKey = typeof bboxData === 'string' ? bboxData : JSON.stringify(bboxData);
  
  // Check cache first
  if (bboxCache.has(cacheKey)) {
    return bboxCache.get(cacheKey) || null;
  }

  // Parse string data if needed
  const parsedData = typeof bboxData === 'string' ? JSON.parse(bboxData) : bboxData;

  if (!parsedData?.slices?.length) {
    bboxCache.set(cacheKey, null);
    return null;
  }

  // Initialize with first bbox values
  const summary: Omit<BBoxSummary, 'center'> = {
    xmin: Infinity,
    ymin: Infinity,
    xmax: -Infinity,
    ymax: -Infinity,
    sliceRange: {
      start: Infinity,
      end: -Infinity,
      bestSlice: {
        idx: -1,
        importance: -1
      }
    }
  };

  // Process slices only once
  const processedSlices = new Set();
  
  parsedData.slices.forEach((slice: any) => {
    const { bbox, slice_idx, slice_importance } = slice;
    
    // Skip if we've already processed this slice
    if (processedSlices.has(slice_idx)) {
      return;
    }
    processedSlices.add(slice_idx);

    summary.xmin = Math.min(summary.xmin, bbox.xmin);
    summary.ymin = Math.min(summary.ymin, bbox.ymin);
    summary.xmax = Math.max(summary.xmax, bbox.xmax);
    summary.ymax = Math.max(summary.ymax, bbox.ymax);

    summary.sliceRange.start = Math.min(summary.sliceRange.start, slice_idx);
    summary.sliceRange.end = Math.max(summary.sliceRange.end, slice_idx);

    if (slice_importance > summary.sliceRange.bestSlice.importance) {
      summary.sliceRange.bestSlice = {
        idx: slice_idx,
        importance: slice_importance
      };
    }
  });

  const result = {
    ...summary,
    center: {
      x: Math.round((summary.xmin + summary.xmax) / 2),
      y: Math.round((summary.ymin + summary.ymax) / 2),
      z: summary.sliceRange.bestSlice.idx
    }
  };

  // Cache the result
  bboxCache.set(cacheKey, result);
  return result;
};

// Add cache clearing function
export const clearBBoxCache = () => {
  bboxCache.clear();
};

export const checkSpatialOverlap = (a: BBoxSummary, b: BBoxSummary): boolean => {
  // Add padding to bounding boxes for more lenient grouping
  const padding = 20;

  const xOverlap = !(
    a.xmax + padding < b.xmin ||
    a.xmin - padding > b.xmax
  );

  const yOverlap = !(
    a.ymax + padding < b.ymin ||
    a.ymin - padding > b.ymax
  );

  // Increase slice range for z-axis overlap
  const zOverlap = Math.abs(
    a.sliceRange.bestSlice.idx - b.sliceRange.bestSlice.idx
  ) <= 10; // Increased from 5 to 10

  return xOverlap && yOverlap && zOverlap;
};

export const logPredictionDetails = (predictions: Record<string, any>, modelType: string): void => {
  // Add debug flag to control logging
  const DEBUG = false;
  if (!DEBUG) return;

  console.group(`🔍 Prediction Details (${modelType})`);

  // Get all findings that are either detected or suspected
  const significantFindings = Object.entries(predictions)
    .filter(([key, value]) => key.startsWith('class_') && (value === 'detected' || value === 'suspected'))
    .map(([key, status]): Finding => {
      const condition = key.replace('class_', '');
      const bboxKey = `bbox_${condition}`;
      const bboxData = typeof predictions[bboxKey] === 'string'
        ? JSON.parse(predictions[bboxKey])
        : predictions[bboxKey];

      return {
        condition,
        status: status as 'detected' | 'suspected',
        probability: predictions[condition] || 0,
        bboxSummary: getBBoxSummary(bboxData)
      };
    })
    .sort((a, b) => b.probability - a.probability);

  // Group findings by spatial proximity
  const spatialGroups: Record<string, Finding[]> = {};

  significantFindings.forEach((finding, idx) => {
    // Skip findings without bbox
    if (!finding.bboxSummary) {
      console.group(`${finding.status === 'detected' ? '✅' : '⚠️'} ${finding.condition} (No location data)`);
      console.groupEnd();
      return;
    }

    let groupKey = `group_${idx}`;
    let foundGroup = false;

    // Check if this finding belongs to an existing group
    Object.entries(spatialGroups).forEach(([key, group]) => {
      group.forEach(existingFinding => {
        if (!existingFinding.bboxSummary || !finding.bboxSummary) return;

        if (checkSpatialOverlap(finding.bboxSummary, existingFinding.bboxSummary)) {
          groupKey = key;
          foundGroup = true;
        }
      });
    });

    // Add to existing group or create new group
    if (foundGroup) {
      spatialGroups[groupKey].push(finding);
    } else {
      spatialGroups[groupKey] = [finding];
    }
  });

  // Log findings by spatial groups
  Object.entries(spatialGroups).forEach(([groupKey, findings], groupIndex) => {
    console.group(`📍 Spatial Group ${groupIndex + 1}`);

    findings.forEach(finding => {
      console.group(`${finding.status === 'detected' ? '✅' : '⚠️'} ${finding.condition}`);

      console.groupEnd();
    });

    console.groupEnd();
  });

  console.groupEnd();
};