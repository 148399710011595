import type { AxiosError } from 'axios';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { getBBoxSummary, checkSpatialOverlap, type BBoxSummary } from './bboxUtils';
import { API_BASE_URL } from '../config/api';

export interface Finding {
  text: string;
  group_id: string;
  bbox?: {
    coordinates: {
      x: number;
      y: number;
      slice: number;
    };
  };
}
export interface OrganizedFindings {
  [organ: string]: {
    detected: string[];
    suspected: string[];
  };
}

export interface SpatialGroupFinding {
  condition: string;
  status: 'detected' | 'suspected';
  probability: number;
  bboxSummary: BBoxSummary;
}

export interface GroupBBox {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  startSlice: number;
  endSlice: number;
}

export interface SpatialGroup {
  id: string;
  findings: SpatialGroupFinding[];
  center: {
    x: number;
    y: number;
    slice: number;
  };
  bbox: GroupBBox;
}

export interface ReportInput {
  findings: OrganizedFindings;
  spatialGroups: SpatialGroup[];
}

interface OpenAIErrorResponse {
  error?: {
    message: string;
    type: string;
    code: string;
  };
}

export interface GroupedFinding {
  group_id?: string;
  text: string;
}

export interface RadiologyReport {
  findings: GroupedFinding[];
  impression: string;
}

export interface StructuredReport {
  findings: GroupedFinding[];
  impression: string;
}

const logOpenAIError = (error: AxiosError<OpenAIErrorResponse>) => {
  if (error.response) {
    console.error('OpenAI API Error Response:', error.response.data);
  } else if (error.request) {
    console.error('OpenAI API No Response:', error.request);
  } else {
    console.error('OpenAI API Error:', error.message);
  }
};

const prepareUserContent = (input: ReportInput) => {
  try {
    const findingsByOrgan = input.findings;
    const spatialGroups = input.spatialGroups;

    // Create a mapping from condition to its location data and group ID
    const conditionLocations: Record<string, { bbox: GroupBBox; sliceRange: { start: number; end: number }; groupId: string }> = {};

    spatialGroups.forEach((group) => {
      group.findings.forEach((f) => {
        const conditionKey = f.condition;
        conditionLocations[conditionKey] = {
          bbox: group.bbox,
          sliceRange: {
            start: group.bbox.startSlice,
            end: group.bbox.endSlice,
          },
          groupId: group.id
        };
      });
    });

    // Prepare the descriptions grouped by organ
    const organDescriptions = Object.entries(findingsByOrgan)
      .map(([organ, statuses]) => {
        const detectedFindings = statuses.detected.map((finding) => {
          const condition = `${organ}/${finding}`;
          const location = conditionLocations[condition];
          return `- ${finding} (detected) [group_id: ${location?.groupId}] at slices ${location?.sliceRange.start}-${location?.sliceRange.end}, bbox coordinates from (${location?.bbox.minX}, ${location?.bbox.minY}) to (${location?.bbox.maxX}, ${location?.bbox.maxY})`;
        });
        const suspectedFindings = statuses.suspected.map((finding) => {
          const condition = `${organ}/${finding}`;
          const location = conditionLocations[condition];
          return `- ${finding} (suspected) [group_id: ${location?.groupId}] at slices ${location?.sliceRange.start}-${location?.sliceRange.end}, bbox coordinates from (${location?.bbox.minX}, ${location?.bbox.minY}) to (${location?.bbox.maxX}, ${location?.bbox.maxY})`;
        });

        return `Organ: ${organ}\n${[...detectedFindings, ...suspectedFindings].join('\n')}`;
      })
      .join('\n\n');

    return organDescriptions;
  } catch (error) {
    return 'Error processing findings';
  }
};

export const generateReport = async (
  predictions: Record<string, string | number>,
  auth: {
    token: string | null;
    userId: string | null;
  }
): Promise<StructuredReport | null> => {
  if (!auth.token || !auth.userId) {
    console.error('Authentication required');
    return null;
  }

  try {
    const response = await axios.post(
      `${API_BASE_URL}/generate_prelim`,
      {
        user_id: auth.userId,
        predictions
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`,
          'Ocp-Apim-Subscription-Key': 'replace_with_subscription_key'
        }
      }
    );

    if (response.data.status === 200 && response.data.prelim) {
      return response.data.prelim as StructuredReport;
    }

    console.error('Failed to generate report:', response.data.message);
    return null;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error generating report:', error.response?.data || error.message);
    } else {
      console.error('Error generating report:', error);
    }
    return null;
  }
};


export const organizeFindings = (predictions: Record<string, string | number>): OrganizedFindings => {
  const findings: OrganizedFindings = {};

  Object.entries(predictions)
    .filter(([key]) => key.startsWith('class_'))
    .forEach(([key, status]) => {
      const condition = key.replace('class_', '');
      const [organ, finding] = condition.split('/');

      if (status === 'detected' || status === 'suspected') {
        if (!findings[organ]) {
          findings[organ] = { detected: [], suspected: [] };
        }

        if (status === 'detected') {
          findings[organ].detected.push(finding);
        } else {
          findings[organ].suspected.push(finding);
        }
      }
    });

  return findings;
};

export const calculateGroupBBox = (findings: SpatialGroupFinding[]): GroupBBox => {
  if (!findings.length) {
    throw new Error('Cannot calculate bbox for empty findings array');
  }

  const bbox: GroupBBox = {
    minX: findings[0].bboxSummary.xmin,
    maxX: findings[0].bboxSummary.xmax,
    minY: findings[0].bboxSummary.ymin,
    maxY: findings[0].bboxSummary.ymax,
    startSlice: findings[0].bboxSummary.sliceRange.start,
    endSlice: findings[0].bboxSummary.sliceRange.end
  };

  findings.forEach((finding) => {
    const summary = finding.bboxSummary;
    bbox.minX = Math.min(bbox.minX, summary.xmin);
    bbox.maxX = Math.max(bbox.maxX, summary.xmax);
    bbox.minY = Math.min(bbox.minY, summary.ymin);
    bbox.maxY = Math.max(bbox.maxY, summary.ymax);
    bbox.startSlice = Math.min(bbox.startSlice, summary.sliceRange.start);
    bbox.endSlice = Math.max(bbox.endSlice, summary.sliceRange.end);
  });

  return bbox;
};

const calculateCenter = (bbox: GroupBBox) => ({
  x: bbox.minX + (bbox.maxX - bbox.minX) / 2,
  y: bbox.minY + (bbox.maxY - bbox.minY) / 2,
  slice: Math.round((bbox.startSlice + bbox.endSlice) / 2)
});

export const createSpatialGroups = (predictions: Record<string, any>): SpatialGroup[] => {
  return Object.entries(predictions)
    .filter(([key]) => key.startsWith('bbox_'))
    .reduce((groups: SpatialGroup[], [key, value]) => {
      const condition = key.replace('bbox_', '');
      const status = predictions[`class_${condition}`] as 'detected' | 'suspected';
      const probability = predictions[condition] as number;

      let bboxData;
      try {
        bboxData = typeof value === 'string' ? JSON.parse(value) : value;
      } catch (error) {
        console.error(`Failed to parse bbox data for ${condition}:`, error);
        return groups;
      }

      const bboxSummary = getBBoxSummary(bboxData);
      if (!bboxSummary) return groups;

      const newFinding: SpatialGroupFinding = {
        condition,
        status,
        probability,
        bboxSummary
      };

      let foundGroup = false;
      for (const group of groups) {
        const overlapsWithAny = group.findings.some(existingFinding =>
          checkSpatialOverlap(existingFinding.bboxSummary, bboxSummary)
        );

        if (overlapsWithAny) {
          group.findings.push(newFinding);

          group.bbox = calculateGroupBBox(group.findings);

          group.center = calculateCenter(group.bbox);

          foundGroup = true;
          break;
        }
      }

      if (!foundGroup) {
        const bbox = calculateGroupBBox([newFinding]);
        const newGroup: SpatialGroup = {
          id: `Group ${groups.length + 1}`,
          findings: [newFinding],
          bbox,
          center: calculateCenter(bbox)
        };
        groups.push(newGroup);
      }

      return groups;
    }, []);
};
