import React from 'react';
import { motion } from 'framer-motion';
import { Sun, Moon } from 'lucide-react';
import { Link } from 'react-router-dom';

// Footer Component
interface FooterProps {
  isDarkMode: boolean;
  toggleTheme: () => void;
}

export const Footer: React.FC<FooterProps> = ({ isDarkMode, toggleTheme }) => {
  const footerLinks = [
    { text: 'Home', href: '/' },
    { text: 'Privacy Policy', href: '/privacy-policy' },
  ];

  const renderFooterSection = (title: string, links: typeof footerLinks) => (
    <div className="flex flex-col items-start">
      <h3 className="text-xs font-medium text-gray-400 uppercase tracking-wider mb-4">{title}</h3>
      <ul className="space-y-2">
        {links.map((link, index) => (
          <li key={index}>
            <Link
              to={link.href}
              className="text-sm text-gray-500 hover:text-gray-300 transition-colors duration-200"
            >
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <footer className="bg-gray-900/95 backdrop-blur-md border-t border-gray-800/10 py-8 px-4">
      <motion.div
        className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className="flex flex-col items-start">
          <span className="text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary via-accent to-primary mb-2">
            a2z
          </span>
          <p className="text-sm text-gray-500">Boston, MA</p>
        </div>

        {renderFooterSection("Quick Links", footerLinks)}

        <div className="flex flex-col items-start">
          <h3 className="text-xs font-medium text-gray-400 uppercase tracking-wider mb-4">About</h3>
          <p className="text-sm text-gray-500">
            a2z-1 and a2z-2 are intended for research, educational, and analytical purposes. They are not approved for clinical or diagnostic use.
          </p>
        </div>
      </motion.div>

      <motion.div
        className="mt-8 pt-6 border-t border-gray-800/30 flex flex-col md:flex-row items-center justify-between max-w-6xl mx-auto"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <p className="text-sm text-gray-500">&copy; 2024 a2z Radiology AI. All rights reserved.</p>
        <button
          onClick={toggleTheme}
          className="mt-4 md:mt-0 text-gray-500 hover:text-gray-300 transition-colors duration-200 focus:outline-none"
        >
          {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
        </button>
      </motion.div>
    </footer>
  );
};

// ScrollProgressBar Component
export const ScrollProgressBar: React.FC = () => {
  const [scrollProgress, setScrollProgress] = React.useState(0);

  React.useEffect(() => {
    const updateScrollProgress = () => {
      const scrollPx = document.documentElement.scrollTop;
      const winHeightPx =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = `${(scrollPx / winHeightPx) * 100}%`;

      setScrollProgress(Number(scrolled.replace('%', '')));
    };

    window.addEventListener('scroll', updateScrollProgress);

    // Initial calculation
    updateScrollProgress();

    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: `${scrollProgress}%`,
        height: '2px',
        backgroundColor: 'var(--color-primary)',
        transition: 'width 0.1s ease-out',
        zIndex: 9999,
      }}
    />
  );
};

// ShinyButton Component
interface ShinyButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}

export const ShinyButton: React.FC<ShinyButtonProps> = ({ onClick, children, className = '' }) => (
  <motion.button
    className={`relative overflow-hidden bg-primary text-white font-bold py-3 px-6 text-lg transition-all flex items-center shine-effect ${className}`}
    onClick={onClick}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <div className="flex items-center relative z-10">
      {children}
    </div>
  </motion.button>
);
