import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Footer, ScrollProgressBar } from './components/Shared';
import { Navbar } from './components/Navbar';
import { ErrorBoundary } from 'react-error-boundary';
import { FaExclamationTriangle } from 'react-icons/fa';
import NotFound from './components/NotFound';
import Worklist from './components/Worklist';
import { AuthProvider } from './context/AuthContext';
import Login from './components/Login';
import ChangePassword from './components/ChangePassword';
import { PrivateRoute } from './components/PrivateRoute';
import { DownloadCSV } from './components/DownloadCSV';
import AddUser from './components/AddUser';
import EditPromptPage from './components/EditPromptPage';
import { OpenAIConfigProvider } from './context/OpenAIConfigContext';
import { WorkerProvider } from './context/WorkerContext';

// Update the NavbarConfig interface to remove modelSwitcher
export interface NavbarConfig {
  title?: string;
  onLoadAllStudies?: () => void;
  isLoadingAll?: boolean;   
}

const ErrorFallback: React.FC<{
  error: Error;
  resetErrorBoundary: () => void;
}> = ({ error, resetErrorBoundary }) => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-light dark:bg-dark">
      <div className="bg-light dark:bg-secondary p-8 rounded-lg shadow-md max-w-md w-full">
        <div className="flex items-center mb-4">
          <FaExclamationTriangle className="text-red-500 text-3xl mr-3" />
          <h2 className="text-2xl font-bold text-dark dark:text-light">
            Oops! Something went wrong
          </h2>
        </div>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          We're sorry, but an error occurred while processing your request.
        </p>
        <div className="bg-red-100 dark:bg-red-900 border-l-4 border-red-500 dark:border-red-700 text-red-700 dark:text-red-300 p-4 mb-4">
          <p className="font-bold">Error details:</p>
          <p className="text-sm">{error.message}</p>
        </div>
        <button
          onClick={resetErrorBoundary}
          className="bg-primary hover:bg-primary-dark dark:bg-primary dark:hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full transition-colors duration-200"
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    return true;
  });
  const [navbarConfig, setNavbarConfig] = useState<NavbarConfig>({
    title: 'Worklist'
  });
  const location = useLocation();

  // Update navbar config based on route
  useEffect(() => {
    if (location.pathname === '/login') {
      setNavbarConfig({ title: 'Login' });
    }
  }, [location]);

  useEffect(() => {
    const root = document.documentElement;
    if (isDarkMode) {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleTheme = () => setIsDarkMode((prevMode) => !prevMode);

  return (
    <WorkerProvider>
    <AuthProvider>
      <OpenAIConfigProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className={isDarkMode ? 'dark' : ''}>
          <div className="flex flex-col min-h-screen bg-gray-900">
          {location.pathname !== '/login' && <Navbar config={navbarConfig} />}
            <ScrollProgressBar />
            <main className="flex-1">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/change-password"
                  element={
                    <PrivateRoute>
                      <ChangePassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/download-csv"
                  element={
                    <PrivateRoute>
                      <DownloadCSV />
                    </PrivateRoute>
                  }
                />
                <Route path="/" element={<Navigate to="/worklist" replace />} />
                <Route
                  path="/worklist"
                  element={
                    <PrivateRoute>
                      <Worklist setNavbarConfig={setNavbarConfig} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/add-user"
                  element={
                    <PrivateRoute>
                      <AddUser />
                    </PrivateRoute>
                  }
                />
                <Route 
                  path="/edit-report-prompt" 
                  element={
                    <PrivateRoute>
                      <EditPromptPage />
                    </PrivateRoute>
                  } 
                />
                <Route
                  path="/case-viewer/:studyId"
                  element={
                    <PrivateRoute>
                      <Worklist viewMode="case" setNavbarConfig={setNavbarConfig} />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
            <Footer isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
          </div>
        </div>
      </ErrorBoundary>
      </OpenAIConfigProvider>
    </AuthProvider>
    </WorkerProvider>
  );
};

export default App;
