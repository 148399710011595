import { useState, useCallback, useRef } from 'react';
import { NpzData } from '../components/types';

interface SliceMessage {
  type: 'slice';
  filename: string;
  sliceIndex: number;
  totalSlices: number;
  sliceData: Int16Array[];
  progress: number;
}

interface SliceBuffer {
  data: (Int16Array[] | undefined)[];
  totalSlices: number;
  receivedSlices: number;
  isInitialized: boolean;
}

export function useSliceAssembler() {
  const [sliceBuffers, setSliceBuffers] = useState<Record<string, SliceBuffer>>({});
  
  const handleSliceMessage = useCallback((
    message: SliceMessage, 
    studyId: string,
    onProgress: (progress: number) => void,
    onSliceAvailable: (sliceIndex: number, sliceData: Int16Array[]) => void,
    onComplete: (npzData: NpzData) => void
  ) => {
    const { filename, sliceIndex, sliceData, totalSlices, progress} = message;
    // Immediately propagate the worker's progress
    const safeProgress = Math.min(progress, 100);
    onProgress(safeProgress);
    setSliceBuffers(prev => {
      const newBuffers = { ...prev };
      
      if (!newBuffers[filename]) {
        newBuffers[filename] = {
          data: Array(totalSlices).fill(undefined),
          totalSlices,
          receivedSlices: 0,
          isInitialized: false
        };
      }
      
      const buffer = newBuffers[filename];
      
      // Only count if this slot was empty
      if (!buffer.data[sliceIndex]) {
        buffer.receivedSlices++;
      }
      buffer.data[sliceIndex] = sliceData;
      
      // Immediately notify about the newly available slice
      onSliceAvailable(sliceIndex, sliceData);

      
      if (buffer.receivedSlices === totalSlices) {
        const npzData: NpzData = {
          data: buffer.data as Int16Array[][],
          dims: [totalSlices, 512, 512],
          filename,
          studyId,
          predictions: {}
        };
        onComplete(npzData);
        delete newBuffers[filename];
      }
      
      return newBuffers;
    });
  }, []);

  return {
    handleSliceMessage,
    clearBuffers: () => {
      setSliceBuffers({});
    }
  };
}
