export const formatText = (text: string): string => {
  return text
    .replace(/\[.*?\]/g, '') // Remove anything in square brackets
    .replace(/_/g, ' ')      // Replace underscores with spaces
    .toLowerCase()           // Convert to lowercase
    .split(' ')             // Split into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
    .join(' ')              // Join back together
    .trim();                // Remove extra spaces
};

export const formatOrganName = (organ: string): string => formatText(organ);
export const formatFindingName = (finding: string): string => formatText(finding);
export const formatConditionDisplay = (condition: string): string => {
  const parts = condition.split('/');
  const finding = parts[1] || parts[0];
  return formatText(finding);
};