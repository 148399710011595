export const getBaseFilename = (filename: string): string => {
  // Extract the study ID from NPZ_{studyId}.npz format
  const match = filename.match(/NPZ_(.+?)\.npz/);
  if (!match) return filename;

  // Get the study ID
  const studyId = match[1];
  return studyId;
};

export const extractFilenameFromUrl = (url: string): string => {
  const match = url.match(/\/(?:SLICE_\d+_)?NPZ_([^?]+)\.npz/);
  if (match) {
    return `NPZ_${match[1]}.npz`;
  }
  return url;
};

export const parseBoundingBoxes = (predictions: Record<string, any>) => {
  const bboxes: Record<string, any> = {};

  Object.entries(predictions).forEach(([key, value]) => {
    if (key.startsWith('bbox_')) {
      const condition = key.replace('bbox_', '');
      try {
        bboxes[condition] = JSON.parse(value as string);
      } catch (e) {
        console.error(`Error parsing bbox for ${condition}:`, e);
        bboxes[condition] = {};
      }
    }
  });

  return bboxes;
};