import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FaSpinner } from 'react-icons/fa';
import axios from 'axios';
import { API_BASE_URL } from '../config/api';

const ChangePassword: React.FC = () => {
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const { userId, token } = useAuth();
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswords(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(false); // Reset success state

    try {
      const payload = {
        password: passwords.currentPassword,
        user_id: userId,
        new_password_1: passwords.newPassword1,
        new_password_2: passwords.newPassword2
      };

      const response = await axios.post(
        `${API_BASE_URL}/api/auth/change_password`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": "replace_with_subscription_key",
            "Authorization": `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        if (response.data.status == 200){
            setSuccess(true);
            setPasswords({
                currentPassword: '',
                newPassword1: '',
                newPassword2: ''
            });
            // Redirect after successful password change
            setTimeout(() => {
                navigate('/worklist');
            }, 2000);
        }
        else{
            setError(response.data.message);
        }
        } else {
            // Handle non-200 success status codes
            setError('Failed to change password. Please try again.');
          }
        } catch (error: any) {
            // Handle error response
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              setError(error.response.data.message || 'Failed to change password. Please check your input and try again.');
            } else if (error.request) {
              // The request was made but no response was received
              setError('No response received from server. Please try again later.');
            } else {
              // Something happened in setting up the request that triggered an Error
              setError('An error occurred while changing password. Please try again.');
            }
            
            // Log the full error for debugging
            console.error('Password change error:', error);
          } finally {
            setIsLoading(false);
          }
        };

  return (
    <div className="flex min-h-screen bg-gray-900 text-white">
      <div className="flex-1 flex items-center justify-center p-4">
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-md bg-gray-800/50 backdrop-blur-md border border-gray-700/50 rounded-lg p-8 shadow-2xl"
        >
          <div className="text-center mb-8">
            <h2 className="text-xl font-medium text-gray-300">Change Password</h2>
          </div>

          {error && (
            <div className="mb-6 p-3 bg-red-500/10 border border-red-500/50 rounded-lg">
              <p className="text-sm text-red-400">{error}</p>
            </div>
          )}

          {success && (
            <div className="mb-6 p-3 bg-green-500/10 border border-green-500/50 rounded-lg">
              <p className="text-sm text-green-400">Password successfully changed! Redirecting...</p>
            </div>
          )}

          <div className="space-y-6">
            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                Current Password
              </label>
              <input
                type="password"
                name="currentPassword"
                value={passwords.currentPassword}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
                required
              />
            </div>

            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                New Password
              </label>
              <input
                type="password"
                name="newPassword1"
                value={passwords.newPassword1}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
                required
              />
            </div>

            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                Confirm New Password
              </label>
              <input
                type="password"
                name="newPassword2"
                value={passwords.newPassword2}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
                required
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full bg-gradient-to-r from-primary/80 to-primary
                hover:from-primary hover:to-primary-light
                text-white font-medium py-2 rounded-lg
                focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-900
                transition-all duration-200 ease-in-out
                disabled:opacity-50 disabled:cursor-not-allowed
                flex items-center justify-center"
            >
              {isLoading ? (
                <>
                  <FaSpinner className="animate-spin mr-2 h-4 w-4" />
                  <span>Changing password...</span>
                </>
              ) : (
                <span>Change Password</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;