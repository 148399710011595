import React, { createContext, useContext, useRef, useCallback } from 'react';

interface WorkerContextType {
  registerWorker: (workerId: string, worker: Worker) => void;
  unregisterWorker: (workerId: string) => void;
  terminateAllWorkers: () => void;
  activeWorkers: React.MutableRefObject<Map<string, Worker>>;
}

const WorkerContext = createContext<WorkerContextType | null>(null);

export const WorkerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const activeWorkers = useRef<Map<string, Worker>>(new Map());

  const registerWorker = useCallback((workerId: string, worker: Worker) => {
    activeWorkers.current.set(workerId, worker);
  }, []);

  const unregisterWorker = useCallback((workerId: string) => {
    activeWorkers.current.delete(workerId);
  }, []);

  const terminateAllWorkers = useCallback(() => {
    activeWorkers.current.forEach((worker, workerId) => {
      worker.terminate();
      activeWorkers.current.delete(workerId);
    });
  }, []);

  return (
    <WorkerContext.Provider value={{ 
      registerWorker, 
      unregisterWorker, 
      terminateAllWorkers,
      activeWorkers 
    }}>
      {children}
      </WorkerContext.Provider>
  );
};

export const useWorker = () => {
  const context = useContext(WorkerContext);
  if (!context) {
    throw new Error('useWorker must be used within a WorkerProvider');
  }
  return context;
};