import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useOpenAIConfig } from '../context/OpenAIConfigContext';
import { FaCheck, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { API_BASE_URL } from '../config/api';

const DEFAULT_CONFIG = {
  model: "gpt-4o",
  temperature: 0.3,
  responseFormat: {
    type: "json_schema",
    json_schema: {
      name: "report_response",
      schema: {
        type: "object",
        properties: {
          findings: {
            type: "array",
            items: {
              type: "object",
              properties: {
                group_id: {
                  type: "string"
                },
                text: {
                  type: "string"
                }
              },
              required: [
                "group_id",
                "text"
              ],
              additionalProperties: false
            }
          }
        },
        required: [
          "findings"
        ],
        additionalProperties: false
      },
      strict: true
    }
  }
};

// Add these types at the top of the file
interface ResponseFormat {
  type: string;
  json_schema: {
    name: string;
    schema: {
      type: string;
      properties: {
        findings: {
          type: string;
          items: {
            type: string;
            properties: {
              group_id: { type: string; };
              text: { type: string; };
            };
            required: string[];
            additionalProperties: boolean;
          };
        };
      };
      required: string[];
      additionalProperties: boolean;
    };
    strict: boolean;
  };
}

interface EditedConfig {
  model: string;
  temperature: number;
  responseFormat: ResponseFormat;
  rawText?: string; 
}


const EditPromptPage: React.FC = () => {
  const {
    config,
    updateConfig,
    availableConfigs,
    configNames,
    selectedConfigName,
    setSelectedConfigName,
    saveConfig, 
    prodConfigName
  } = useOpenAIConfig();
  
  const [editedPrompt, setEditedPrompt] = useState(config.systemPrompt);
  const [editedConfig] = useState<EditedConfig>(DEFAULT_CONFIG);

  const [isEditing, setIsEditing] = useState(false);
  const [isPromptExpanded, setIsPromptExpanded] = useState(true);
  const [isConfigExpanded, setIsConfigExpanded] = useState(true);
  const [isConfigSelectorOpen, setIsConfigSelectorOpen] = useState(false);
  const [setAsProd, setSetAsProd] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newConfigName, setNewConfigName] = useState('');
  const { token, adminUserId } = useAuth();

  useEffect(() => {
    if (prodConfigName && configNames.includes(prodConfigName)) {
      handleConfigSelect(prodConfigName);
    }
  }, [prodConfigName, configNames]);

  useEffect(() => {
    setEditedPrompt(config.systemPrompt);
  }, [config]);

  const handleSave = async () => {
    try {
      setIsSaving(true);  // Set flag before saving
      
      // First update the local config with edited values
      const updatedConfig = {
        systemPrompt: editedPrompt,
        ...editedConfig
      };
      
      updateConfig(updatedConfig);
  
      // Then save the edited values to the server
      await saveConfig(selectedConfigName, setAsProd, updatedConfig);
      
      // Exit edit mode after successful save
      setIsEditing(false);
      setSetAsProd(false);
    } catch (error) {
      console.error('Error saving config:', error);
      alert('Failed to save configuration. Please try again.');
    } finally {
      setIsSaving(false);  // Reset flag after save completes
    }
  };

  const handleConfigSelect = (name: string) => {
    // Skip the confirmation if we're not in edit mode or if we're saving
    if (!isEditing || isSaving) {
      setSelectedConfigName(name);
      const index = configNames.indexOf(name);
      if (index !== -1) {
        updateConfig(availableConfigs[index]);
      }
      setIsConfigSelectorOpen(false);
      return;
    }

      // Show confirmation only if in edit mode
  const confirmSwitch = window.confirm(
    'You have unsaved changes. Are you sure you want to switch configurations? Your changes will be lost.'
  );
  
  if (!confirmSwitch) {
    return;
  }
  
  // Exit edit mode and switch config
  setIsEditing(false);
  setSetAsProd(false);
  setSelectedConfigName(name);
  const index = configNames.indexOf(name);
  if (index !== -1) {
    updateConfig(availableConfigs[index]);
  }
  setIsConfigSelectorOpen(false);
};
  
// Update the resetToNewConfig function with proper initial responseFormat
const resetToNewConfig = () => {
  setIsAddingNew(true);
  setNewConfigName('');
  setEditedPrompt(''); // Empty object
  setIsEditing(true);
};


const handleAddNewConfig = async () => {
  if (!newConfigName.trim()) {
    alert('Please provide a configuration name');
    return;
  }

  if (configNames.includes(newConfigName)) {
    alert('A configuration with this name already exists');
    return;
  }

  if (!token || !adminUserId) {
    throw new Error('Authentication required');
  }

  try {
    setIsSaving(true);
    const newConfig = {
      systemPrompt: editedPrompt,
      ...editedConfig
    };

    // Use the saveConfig function from context instead of direct API call
    await saveConfig(newConfigName, setAsProd, newConfig);
    
    // Reset states
    setIsAddingNew(false);
    setIsEditing(false);
    setNewConfigName('');
    
    // If this was set as production config, select it
    if (setAsProd) {
      setSelectedConfigName(newConfigName);
    } else if (prodConfigName) {
      // Otherwise, go back to production config if it exists
      setSelectedConfigName(prodConfigName);
    }

  } catch (error) {
    console.error('Error adding config:', error);
    alert('Failed to add configuration. Please try again.');
  } finally {
    setIsSaving(false);
  }
};


return (
  <div className="min-h-screen bg-dark p-6">
    <Helmet>
      <title>{isAddingNew ? 'New Configuration' : 'Edit OpenAI Prompt'} | a2z Radiology AI</title>
    </Helmet>

    <div className="max-w-4xl mx-auto space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-white">
          {isAddingNew ? 'New OpenAI Configuration' : 'OpenAI Configuration'}
        </h1>
        {!isAddingNew && (
          <button
            onClick={resetToNewConfig}
            className="px-4 py-1.5 rounded-md text-xs font-medium
              bg-green-600/80 hover:bg-green-600
              text-white transition-all duration-200"
          >
            Add New Configuration
          </button>
        )}
      </div>

      <div className="bg-gray-800 rounded-lg p-6 space-y-4">
        {!isAddingNew ? (
          <div className="mb-6">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-lg font-semibold text-white">Available Configurations</h2>
              <div className="flex items-center gap-4">
                {isEditing && selectedConfigName !== prodConfigName && (
                  <label className="flex items-center gap-2 text-sm text-gray-300">
                    <input
                      type="checkbox"
                      checked={setAsProd}
                      onChange={(e) => setSetAsProd(e.target.checked)}
                      className="rounded border-gray-700 bg-gray-900 text-primary focus:ring-primary"
                    />
                    Set as production config
                  </label>
                )}
                <button
                  onClick={() => isEditing ? handleSave() : setIsEditing(true)}
                  className="px-4 py-1.5 rounded-md text-xs font-medium
                    bg-primary/80 hover:bg-primary
                    text-white transition-all duration-200"
                >
                  {isEditing ? 'Save Changes' : 'Edit Configuration'}
                </button>
              </div>
            </div>

            <button
              onClick={() => setIsConfigSelectorOpen(!isConfigSelectorOpen)}
              className="w-full flex items-center justify-between px-4 py-2 text-sm
                bg-gray-900 hover:bg-gray-700 text-white rounded-lg
                border border-gray-700 focus:outline-none focus:ring-2 focus:ring-primary"
            >
              <span>{selectedConfigName}</span>
              <FaChevronDown className="w-4 h-4 text-gray-400" />
            </button>

            {isConfigSelectorOpen && (
              <div className="absolute z-10 mt-2 w-full bg-gray-800 rounded-lg border border-gray-700 shadow-lg">
                <div className="py-1 max-h-60 overflow-auto">
                  {configNames.map((name) => (
                    <button
                      key={name}
                      onClick={() => handleConfigSelect(name)}
                      className="w-full text-left px-4 py-2 text-sm text-gray-200 
                        hover:bg-gray-700 flex items-center justify-between
                        transition-colors duration-150"
                    >
                      <span className="flex items-center space-x-2">
                        <span>
                          {name}
                          {name === prodConfigName && (
                            <span className="ml-2 text-xs text-primary-light">(Production)</span>
                          )}
                        </span>
                      </span>
                      {selectedConfigName === name && (
                        <FaCheck className="w-4 h-4 text-primary-light" />
                      )}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="mb-6">
            <div className="flex items-center gap-4 mb-4">
              <input
                type="text"
                value={newConfigName}
                onChange={(e) => setNewConfigName(e.target.value)}
                placeholder="Enter configuration name"
                className="px-3 py-2 rounded-md text-sm
                  bg-gray-900 text-white border border-gray-700
                  focus:border-primary focus:ring-1 focus:ring-primary outline-none"
              />
              <label className="flex items-center gap-2 text-sm text-gray-300">
                <input
                  type="checkbox"
                  checked={setAsProd}
                  onChange={(e) => setSetAsProd(e.target.checked)}
                  className="rounded border-gray-700 bg-gray-900 text-primary focus:ring-primary"
                />
                Set as production config
              </label>
              <div className="flex gap-2">
                <button
                  onClick={handleAddNewConfig}
                  disabled={isSaving}
                  className="px-4 py-1.5 rounded-md text-xs font-medium
                    bg-green-600/80 hover:bg-green-600
                    text-white transition-all duration-200"
                >
                  {isSaving ? 'Saving...' : 'Save Configuration'}
                </button>
                <button
                  onClick={() => {
                    setIsAddingNew(false);
                    setNewConfigName('');
                    setIsEditing(false);
                  }}
                  className="px-4 py-1.5 rounded-md text-xs font-medium
                    bg-gray-700 hover:bg-gray-600
                    text-white transition-all duration-200"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* System Prompt Section */}
        <div>
          <div 
            className="flex justify-between items-center mb-2 cursor-pointer"
            onClick={() => setIsPromptExpanded(!isPromptExpanded)}
          >
            <div className="flex items-center">
              {isPromptExpanded ? (
                <FaChevronDown className="w-3 h-3 text-gray-400 mr-2" />
              ) : (
                <FaChevronRight className="w-3 h-3 text-gray-400 mr-2" />
              )}
              <h2 className="text-lg font-semibold text-white">System Prompt</h2>
            </div>
          </div>
          {isPromptExpanded && (
            <div className="bg-gray-900 p-4 rounded-md">
              <textarea
                value={editedPrompt}
                onChange={(e) => setEditedPrompt(e.target.value)}
                className="w-full h-[500px] bg-gray-900 text-gray-300 text-sm p-2 
                  border border-gray-700 rounded-md focus:border-primary
                  focus:ring-1 focus:ring-primary outline-none"
              />
            </div>
          )}
        </div>

{/* Model Configuration Section */}
<div>
  <div 
    className="flex justify-between items-center mb-2 cursor-pointer"
    onClick={() => setIsConfigExpanded(!isConfigExpanded)}
  >
    <div className="flex items-center">
      {isConfigExpanded ? (
        <FaChevronDown className="w-3 h-3 text-gray-400 mr-2" />
      ) : (
        <FaChevronRight className="w-3 h-3 text-gray-400 mr-2" />
      )}
      <h2 className="text-lg font-semibold text-white">Model Configuration</h2>
    </div>
  </div>
  {isConfigExpanded && (
  <div className="bg-gray-900 p-4 rounded-md">
    <textarea
      value={JSON.stringify(DEFAULT_CONFIG, null, 2)}
      readOnly
      spellCheck={false}
      className="w-full h-[400px] bg-gray-900 text-gray-300 text-sm p-2 
        border border-gray-700 rounded-md focus:border-primary
        focus:ring-1 focus:ring-primary outline-none font-mono"
        />
      </div>
  )}

        
</div>
      </div>
    </div>
  </div>
);
};

export default EditPromptPage;