import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FaSpinner } from 'react-icons/fa';
import { authService } from '../services/auth';
import UserSelector from './UserSelector';

const Login: React.FC = () => {
  const [credentials, setCredentials] = useState({
    userId: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loginData, setLoginData] = useState<{ 
    token: string; 
    refreshToken: string;
    availableUserIds: string[]; 
    adminUserId?: string; 
  } | null>(null);
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Only clear login data when explicitly logging out
  useEffect(() => {
    if (!auth.isAuthenticated && !location.state?.fromSwitch) {
      setLoginData(null);
      setCredentials({ userId: '', password: '' });
    }
  }, [auth.isAuthenticated, location.state?.fromSwitch]);


  // Handle switch user state
  // Handle switch user state
  useEffect(() => {
    const state = location.state as { 
      token?: string; 
      refreshToken?: string; 
      availableUserIds?: string[];
      adminUserId?: string;
      fromSwitch?: boolean;
    };
    
    if (state?.fromSwitch && state.token && state.refreshToken && state.availableUserIds) {
      // Only set login data if it's not already set with the same values
      setLoginData((prevData): { token: string; refreshToken: string; availableUserIds: string[]; adminUserId?: string; } | null => {
        if (prevData?.token === state.token && 
            prevData?.refreshToken === state.refreshToken && 
            prevData?.adminUserId === state.adminUserId) {
          return prevData;
        }
        return {
          token: state.token as string,
          refreshToken: state.refreshToken as string,
          availableUserIds: state.availableUserIds as string[],
          adminUserId: state.adminUserId
        };
      });
    }
  }, [location.state]);

  const from = (location.state as any)?.from?.pathname || '/worklist';

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const { token, refreshToken, availableUserIds } = await authService.login(credentials.userId, credentials.password);
      
      if (availableUserIds.length === 1) {
        // Cookies are already set by the auth service for single user ID
        auth.setAuthState({
          isAuthenticated: true,
          token,
          refreshToken,
          userId: availableUserIds[0], 
          isAdmin: false
        });
        navigate(from);
      } else {
        // Show selector for multiple user IDs
        setLoginData({ 
          token, 
          refreshToken,  // Now we have this from the login response
          availableUserIds, 
          adminUserId: credentials.userId 
        });
      }
    } catch (error) {
      setError('Login failed. Please check your credentials.');
    } finally {
      setIsLoading(false);
    }
  };
  if (loginData) {
    return (
      <div className="h-screen">
        <UserSelector availableUserIds={loginData.availableUserIds} token={loginData.token} refreshToken={loginData.refreshToken} adminUserId={loginData.adminUserId}/>
      </div>
    );
  }
  return (
    <div className="flex min-h-screen bg-gray-900 text-white">
      <div className="flex-1 flex items-center justify-center p-4">
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-md bg-gray-800/50 backdrop-blur-md border border-gray-700/50 rounded-lg p-8 shadow-2xl"
        >
          {/* Logo and Title */}
          <div className="text-center mb-8">
            <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent">
              a2z
            </span>
            <h2 className="mt-4 text-xl font-medium text-gray-300">Sign In</h2>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mb-6 p-3 bg-red-500/10 border border-red-500/50 rounded-lg">
              <p className="text-sm text-red-400">{error}</p>
            </div>
          )}

          {/* Form Fields */}
          <div className="space-y-6">
            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                User ID
              </label>
              <input
                type="text"
                name="userId"
                value={credentials.userId}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
                required
              />
            </div>

            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                Password
              </label>
              <input
                type="password"
                name="password"
                value={credentials.password}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
                required
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full bg-gradient-to-r from-primary/80 to-primary
                hover:from-primary hover:to-primary-light
                text-white font-medium py-2 rounded-lg
                focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-900
                transition-all duration-200 ease-in-out
                disabled:opacity-50 disabled:cursor-not-allowed
                flex items-center justify-center"
            >
              {isLoading ? (
                <>
                  <FaSpinner className="animate-spin mr-2 h-4 w-4" />
                  <span>Signing in...</span>
                </>
              ) : (
                <span>Sign In</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
