// src/components/Worklist.tsx

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner, FaChevronRight, FaChevronLeft, FaChevronDown,FaSync, FaDownload  } from 'react-icons/fa';
import CaseViewer from './CaseViewer';
import { useAuth } from '../context/AuthContext';
import { StudyData, FeedbackRecord } from './types';
import { API_BASE_URL } from '../config/api';
import { formatOrganName, formatFindingName } from '../utils/textFormatters';
import { NavbarConfig } from '../App';
import {useInView} from '../hooks/useInView'
import {
  type StructuredReport,
  type OrganizedFindings,
  organizeFindings,
} from '../utils/reportGenerator';
import { useOpenAIConfig } from '../context/OpenAIConfigContext';

interface GroupedFinding {
  group_id: string;
  text: string;
}

interface StudyRowProps {
  study: StudyData;
  index: number;
  navigate: (path: string) => void;
  reports: Record<string, StructuredReport>;
  setReports: React.Dispatch<React.SetStateAction<Record<string, StructuredReport>>>;
  generatingReport: Record<string, boolean>;
  generateStudyReport: (studyId: string, findings: OrganizedFindings) => Promise<void>;
  onHover: (studyId: string) => Promise<void>;
  studies: StudyData[];
  showPrelims: boolean;
  token: string | null;
  userId: string | null;
  setStudies: React.Dispatch<React.SetStateAction<StudyData[]>>;
}

interface WorklistProps {
  viewMode?: 'list' | 'case';
  setNavbarConfig: (config: NavbarConfig) => void;
}

const sortStudies = (studies: StudyData[]): StudyData[] => {
  return [...studies].sort((a, b) => {
    // Sort by studyId in ascending order
    return a.studyId.localeCompare(b.studyId);
  });
};


const StatusIndicator: React.FC<{ isReviewed: boolean | undefined }> = ({ isReviewed }) => {
  // If isReviewed is undefined (haven't loaded the data yet), don't show anything
  if (isReviewed === undefined) return null;

  return (
    <div 
      className={`
        w-3 h-3 rounded-full
        ${isReviewed 
          ? 'bg-green-500' 
          : 'bg-red-500'
        }
      `}
      title={isReviewed ? 'Reviewed' : 'Not reviewed'}
    />
  );
};

const PrelimsToggle: React.FC<{ showPrelims: boolean; setShowPrelims: (show: boolean) => void }> = ({ showPrelims, setShowPrelims }) => (
  <button
    onClick={(e) => {
      e.stopPropagation();
      setShowPrelims(!showPrelims);
    }}
    className="px-3 py-1.5 bg-gray-800 hover:bg-gray-700 text-gray-300 hover:text-white rounded-lg text-xs font-medium transition-colors duration-200 flex items-center gap-2"
  >
    {showPrelims ? (
      <>
        <span>Hide Prelims</span>
        <FaChevronRight className="w-3 h-3" />
      </>
    ) : (
      <>
        <FaChevronLeft className="w-3 h-3" />
        <span>Show Prelims</span>
      </>
    )}
  </button>
);

const LoadingWorklist: React.FC = () => (
  <div className="flex-1 flex items-center justify-center p-8">
    <div className="max-w-md w-full mx-4">
      <div className="bg-gray-800 border border-gray-700 rounded-xl p-6 shadow-2xl">
        {/* Header */}
        <div className="mb-6 text-center">
          <div className="inline-block p-3 bg-gray-700/50 rounded-full mb-4">
            <FaSpinner className="animate-spin text-primary w-8 h-8" />
          </div>
          <h3 className="text-lg font-semibold text-white">Loading</h3>
        </div>

        {/* Loading Animation */}
        <div className="space-y-4">
          {[1, 2, 3].map((i) => (
            <div key={i} className="animate-pulse">
              <div className="h-20 bg-gray-700/50 rounded-lg flex items-center p-4">
                <div className="space-y-2 flex-1">
                  <div className="h-3 bg-gray-600 rounded w-1/4"></div>
                  <div className="h-3 bg-gray-600 rounded w-1/2"></div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Loading Message */}
        <div className="mt-6 pt-6 border-t border-gray-700">
          <p className="text-xs text-gray-500 text-center">
            Retrieving your studies...
          </p>
        </div>
      </div>
    </div>
  </div>
);

const LoadingFindingsIndicator: React.FC = () => (
  <div className="flex items-center text-gray-400 text-sm">
    <span className="px-3 py-1.5 bg-gray-700/30 rounded-lg">Loading</span>
  </div>
);

const NoFindingsIndicator: React.FC = () => (
  <button
    className="text-xs text-gray-400 hover:text-gray-300 flex items-center space-x-1 p-1 rounded hover:bg-gray-700/30"
  >
    <FaChevronRight className="w-3 h-3" />
    <span>Show predictions</span>
  </button>
);

const tagStyles = {
  detected: `
    inline-flex items-center px-2 py-1
    bg-primary/20 border border-primary/30
    text-primary-light text-xs font-medium
  `,
  suspected: `
    inline-flex items-center px-2 py-1
    bg-accent/10 border border-accent/20
    text-accent-light text-xs font-medium
  `
} as const;
const CollapsiblePredictions: React.FC<{
  findings: OrganizedFindings;
  defaultExpanded?: boolean;
  isLoading?: boolean;
}> = ({ findings, defaultExpanded = false, isLoading }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  if (isLoading) {
    return <LoadingFindingsIndicator />;
  }

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };


  return (
    <div onClick={(e) => e.stopPropagation()}>
      {!isExpanded ? (
        <button
          onClick={handleToggle}
          className="text-xs text-gray-400 hover:text-gray-300 flex items-center space-x-1 p-1 rounded hover:bg-gray-700/30"
        >
          <FaChevronRight className="w-3 h-3" />
          <span>Show predictions</span>
        </button>
      ) : (
        <div>
          <button
            onClick={handleToggle}
            className="text-xs text-gray-400 hover:text-gray-300 flex items-center space-x-1 mb-2 p-1 rounded hover:bg-gray-700/30"
          >
            <FaChevronDown className="w-3 h-3" />
            <span>Hide predictions</span>
          </button>
          <div className="space-y-3">
            {Object.entries(findings).length === 0 ? (
              <div className="text-xs text-gray-400 px-1">No findings detected</div>
            ) : (
              Object.entries(findings).map(([organ, { detected, suspected }]) => (
                <div key={organ} className="space-y-1.5">
                  <div className="text-xs text-gray-400 font-medium">
                    {formatOrganName(organ)}
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {detected.map(finding => (
                      <span key={finding} className={tagStyles.detected}>
                        {formatFindingName(finding)}
                      </span>
                    ))}
                    {suspected.map(finding => (
                      <span key={finding} className={tagStyles.suspected}>
                        {formatFindingName(finding)}
                      </span>
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// Update the StudyRow component to use the interface
const StudyRow: React.FC<StudyRowProps> = React.memo(({
  study,
  index,
  navigate,
  reports,
  setReports,
  generatingReport,
  generateStudyReport,
  onHover,
  studies, 
  showPrelims, 
  token,
  userId,
  setStudies
}) => {
  const { elementRef, hasLoaded } = useInView({
    threshold: 0.1,
    rootMargin: '100px'
  });

  // Load data when row comes into view
  useEffect(() => {
    if (hasLoaded && !study.base_model_prediction) {
      handleHover();
    }
  }, [hasLoaded]);
  const baseFindings = useMemo(
    () => organizeFindings(study.base_model_prediction || {}),
    [study.base_model_prediction]
  );

  const experimentalFindings = useMemo(
    () => organizeFindings(study.experimental_model_prediction || {}),
    [study.experimental_model_prediction]
  );

  const handleHover = useCallback(async () => {
    // Skip if we already have both the predictions and report
    if (studies.find(s => 
      s.studyId === study.studyId && 
      s.base_model_prediction && 
      s.prelim
    )) {
      return;
    }
  
    // First fetch the study info
    await onHover(study.studyId);
    
    // Get the latest study data after the hover update
    const updatedStudy = studies.find(s => s.studyId === study.studyId);
    if (!updatedStudy) return;

        // Add feedback check
    if (token && userId) {
      try {
        const feedbackResponse = await axios.post(
          `${API_BASE_URL}/get_feedback`,
          {
            study_id: study.studyId,
            user_id: userId
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'Ocp-Apim-Subscription-Key': 'replace_with_subscription_key'
            }
          }
        );

        // Update studies with feedback data
        if (feedbackResponse.data.feedback) {
          setStudies(prev => prev.map(s => 
            s.studyId === study.studyId 
              ? { ...s, feedback: feedbackResponse.data.feedback }
              : s
          ));
        }
      } catch (error) {
        console.error('Error fetching feedback:', error);
      }
    }
  
  
    // Update the reports state with prelim data if available
    if (updatedStudy.prelim) {
      // Filter findings based on prelim_feedback
      const filteredPrelim = {
        ...updatedStudy.prelim,
        findings: updatedStudy.prelim.findings.filter((finding, index) => {
          // If there's no feedback, show the finding
          if (!updatedStudy.prelim_feedback) return true;
          
          // Only show findings that have been accepted
          const feedback = updatedStudy.prelim_feedback[index];
          return feedback?.feedback === 'accepted';
        })
      };

      setReports(prev => ({ 
        ...prev, 
        [study.studyId]: filteredPrelim 
      }));
    }
  }, [study.studyId, onHover, studies, token, userId]);

  const hasPredictions = !!study.base_model_prediction || !!study.experimental_model_prediction;

  return (
    <tr
      ref={elementRef as React.RefObject<HTMLTableRowElement>}
      onClick={() => navigate(`/case-viewer/${study.studyId}`)}
      className={`
        hover:bg-gray-800/40 cursor-pointer
        transition-colors duration-200
        ${index % 2 === 0 ? 'bg-gray-800/20' : 'bg-transparent'}
      `}
    >
      <td className="w-12 px-4 py-4">
        <StatusIndicator 
          isReviewed={study.feedback ? 
            Object.values(study.feedback).some(([status]) => status !== 'pending') 
            : undefined
          } 
        />
      </td>
      <td className="w-48 px-4 py-4">
        <div className="text-sm text-gray-300 font-medium break-all">
          {study.studyId}
        </div>
      </td>
      {/* a2z-1 Predictions - collapsed by default
      <td className="px-4 py-4">
        <CollapsiblePredictions 
          findings={baseFindings} 
          defaultExpanded={false}
          isLoading={!hasPredictions}
        />
      </td> */}
      {/* a2z-2 Predictions - expanded by default */}
      <td className="px-4 py-4">
        <CollapsiblePredictions 
          findings={experimentalFindings} 
          defaultExpanded={true}
          isLoading={!hasPredictions}
        />
      </td>
      {showPrelims && (<td className="px-4 py-4">
        <div className="flex flex-col space-y-4">
        {reports[study.studyId] ? (
            <div>
              <div className="text-gray-300 leading-relaxed space-y-2">
                {reports[study.studyId].findings.map((finding, index) => (
                  <div
                    key={index}
                    className="p-2 rounded bg-gray-700/20 border border-gray-700/10"
                  >
                    {finding.text}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div
              className="text-sm text-gray-500 bg-gray-800/30 rounded-lg p-3 border border-gray-700/20"
              onClick={(e) => e.stopPropagation()}
            >
              Hover to load report
            </div>
          )}
        </div>
      </td>)}
    </tr>
  );
});

const Worklist: React.FC<WorklistProps> = ({ viewMode = 'list', setNavbarConfig }) => {
  const { studyId } = useParams<{ studyId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [studies, setStudies] = useState<StudyData[]>([]);
  const navigate = useNavigate();
  const { token, userId } = useAuth();
  const [generatingReport, setGeneratingReport] = useState<Record<string, boolean>>({});
  const [reports, setReports] = useState<Record<string, StructuredReport>>({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showPrelims, setShowPrelims] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);

  const fetchStudyInfo = useCallback(async (studyId: string) => {
    if (!token || !userId) return;
  
    try {
      const response = await axios.post(
        `${API_BASE_URL}/get_study_info`,
        {
          user_id: userId,
          study_id: studyId
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": "replace_with_subscription_key",
            "Authorization": `Bearer ${token}`
          }
        }
      );
  
      if (response.data.status === 200) {
        const studyInfo = response.data;
        return {
          studyId: studyId,
          isLoading: false,
          base_model_prediction: studyInfo.base_model_prediction,
          experimental_model_prediction: studyInfo.experimental_model_prediction,
          seriesUrls: studyInfo.series_urls,
          selectedSeriesId: studyInfo.selected_series_id, 
          prelim: studyInfo.prelim || null,
          prelim_feedback: studyInfo.prelim_feedback || null, 
          report: studyInfo.report, 
          metadata: studyInfo.metadata || {} 
        };
        
      }
      return null;
    } catch (error) {
      console.error('Error fetching study info:', error);
      return null;
    }
  }, [token, userId]);

  const fetchStudies = useCallback(async (retryCount = 0) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/get_user_study_ids`,
        { user_id: userId },
        {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": "replace_with_subscription_key",
            "Authorization": `Bearer ${token}`
          }
        }
      );

      if (response.data.study_ids) {
        const basicStudies: StudyData[] = response.data.study_ids.map((id: string) => ({
          studyId: id
        }));
        // Sort the studies before setting them
        setStudies(sortStudies(basicStudies));
      }
    } catch (error) {
      console.error('Error fetching studies:', error);
      if (axios.isAxiosError(error) && error.response?.status === 401 && retryCount < 2) {
        setTimeout(() => fetchStudies(retryCount + 1), 1000);
      }
    } finally {
      setIsLoading(false);
    }
  }, [token, userId]);

  const handleStudyHover = useCallback(async (studyId: string) => {
    // Skip if we already have the data
    if (studies.find(s => s.studyId === studyId && s.base_model_prediction)) {
      return;
    }
  
    const studyInfo = await fetchStudyInfo(studyId);
    if (studyInfo) {
      setStudies(prev => prev.map(study => 
        study.studyId === studyId ? { 
          ...study, 
          ...studyInfo,
          prelim: studyInfo.prelim,
          prelim_feedback: studyInfo.prelim_feedback
        } : study
      ));
  
      // If we have prelim data, update reports immediately
      if (studyInfo.prelim) {
        // Filter findings based on prelim_feedback
        const filteredPrelim = {
          ...studyInfo.prelim,
          findings: studyInfo.prelim.findings.filter((finding: GroupedFinding, index: number) => {
            // If there's no feedback, show all findings
            if (!studyInfo.prelim_feedback) return true;
            
            // If we have feedback, only show findings that have been accepted
            const feedback = studyInfo.prelim_feedback[index];
            return feedback?.feedback === 'accepted';
          })
        };
  
        setReports(prev => ({ 
          ...prev, 
          [studyId]: filteredPrelim 
        }));
      }
    }
  }, [studies, fetchStudyInfo]);

    const handleFeedbackUpdate = (studyId: string, updatedFeedback: FeedbackRecord) => {
      setStudies(prevStudies => 
        prevStudies.map(study => 
          study.studyId === studyId 
            ? { ...study, feedback: updatedFeedback }
            : study
        )
      );
    };

  const handleLoadAllStudies = useCallback(async () => {
    if (!token || !userId || isLoadingAll) return;
  
    setIsLoadingAll(true);
    try {
      // Create a queue of studies that haven't been loaded yet
      const unloadedStudies = studies.filter(study => !study.base_model_prediction);
      
      // Process studies in batches of 5 to avoid overwhelming the server
      const batchSize = 5;
      for (let i = 0; i < unloadedStudies.length; i += batchSize) {
        const batch = unloadedStudies.slice(i, i + batchSize);
        
        // First fetch study info for each study in the batch
        await Promise.all(batch.map(study => handleStudyHover(study.studyId)));
        
        // Then fetch feedback for each study in the batch
        await Promise.all(batch.map(async (study) => {
          try {
            const feedbackResponse = await axios.post(
              `${API_BASE_URL}/get_feedback`,
              {
                study_id: study.studyId,
                user_id: userId
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                  'Ocp-Apim-Subscription-Key': 'replace_with_subscription_key'
                }
              }
            );
  
            if (feedbackResponse.data.feedback) {
              setStudies(prev => prev.map(s => 
                s.studyId === study.studyId 
                  ? { ...s, feedback: feedbackResponse.data.feedback }
                  : s
              ));
            }
          } catch (error) {
            console.error('Error fetching feedback:', error);
          }
        }));
      }
    } catch (error) {
      console.error('Error loading all studies:', error);
    } finally {
      setIsLoadingAll(false);
    }
  }, [token, userId, studies, handleStudyHover, isLoadingAll]);

  useEffect(() => {
    if (token && userId) {
      setIsLoading(true);
      fetchStudies().catch(error => {
        console.error('Error fetching studies:', error);
        setStudies([]); // Reset studies on error
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      setStudies([]);
    }
  }, [token, userId]);
  
  const generateStudyReport = useCallback(async (studyId: string, findings: OrganizedFindings) => {
  
    if (!userId) {
      return;
    }
  
    if (reports[studyId]) {
      return;
    }
  
    if (generatingReport[studyId]) {
      return;
    }
  
    setGeneratingReport((prev) => ({ ...prev, [studyId]: true }));
    
    try {
      const study = studies.find((s) => s.studyId === studyId);
      if (!study) {
        return;
      }
  
      // If study has prelim data, use it directly
      if (study.prelim) {
        setReports((prev) => ({ ...prev, [studyId]: study.prelim! }));
      } 
    } catch (error) {
      console.error('Error loading report:', error);
    } finally {
      setGeneratingReport((prev) => ({ ...prev, [studyId]: false }));
    }
  }, [studies, reports, generatingReport, userId]);
    
  const refreshStudies = useCallback(async () => {
    if (!token || !userId || isRefreshing) return;
  
    try {
      setIsRefreshing(true);
      const response = await axios.post(
        `${API_BASE_URL}/refresh_user_study_ids`,
        { user_id: userId },
        {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": "replace_with_subscription_key",
            "Authorization": `Bearer ${token}`
          }
        }
      );
  
      if (response.data.study_ids) {
        const basicStudies: StudyData[] = response.data.study_ids.map((id: string) => ({
          studyId: id,
          isLoading: false
        }));
        
        // First set the studies
        setStudies(sortStudies(basicStudies));
        
        // Load studies in batches
        const unloadedStudies = basicStudies;
        const batchSize = 5;
        
        for (let i = 0; i < unloadedStudies.length; i += batchSize) {
          const batch = unloadedStudies.slice(i, i + batchSize);
          await Promise.all(batch.map(async study => {
            // Load study info
            const studyInfo = await fetchStudyInfo(study.studyId);
            
            // Load feedback status
            const feedbackResponse = await axios.post(
              `${API_BASE_URL}/get_feedback`,
              {
                study_id: study.studyId,
                user_id: userId
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                  'Ocp-Apim-Subscription-Key': 'replace_with_subscription_key'
                }
              }
            );
  
            // Combine study info and feedback
            if (studyInfo) {
              setStudies(prev => prev.map(s => 
                s.studyId === study.studyId 
                  ? { 
                      ...s, 
                      ...studyInfo,
                      feedback: feedbackResponse.data.feedback || null 
                    } 
                  : s
              ));
            }
          }));
        }
      }
    } catch (error) {
      console.error('Error refreshing studies:', error);
    } finally {
      setIsRefreshing(false);
    }
  }, [token, userId, isRefreshing, fetchStudyInfo]);

  useEffect(() => {
    if (token && userId) {
      fetchStudies();
    } else {
      setStudies([]);
    }
  }, [token, userId, fetchStudies]);

  useEffect(() => {
    setNavbarConfig({
      title: viewMode === 'case' ? 'Viewer' : 'Worklist',
      onLoadAllStudies: handleLoadAllStudies,
      isLoadingAll: isLoadingAll
    });
  }, [viewMode, setNavbarConfig, handleLoadAllStudies, isLoadingAll]);

  // Case viewer mode handling
  if (viewMode === 'case' && studyId) {
    const selectedStudy = studies.find((s) => s.studyId === studyId);

    if (!token || !userId) {
      navigate('/login');
      return null;
    }

    if (isLoading) {
      return <LoadingWorklist />;
    }

    if (!selectedStudy) {
      // If we have no studies but are authenticated, try to fetch them
      if (studies.length === 0 && token && userId) {
        fetchStudies();
        return <LoadingWorklist />;
      }

      return (
        <div className="flex h-screen bg-dark text-white items-center justify-center">
          <div className="text-center">
            <h2 className="text-xl font-medium mb-4">Study not found</h2>
            <button
              onClick={() => navigate('/worklist')}
              className="px-4 py-2 bg-primary hover:bg-primary-dark rounded-lg transition-colors"
            >
              Return to Worklist
            </button>
          </div>
        </div>
      );
    }

    return (
      <CaseViewer
        studyData={{
          series: selectedStudy.series,
          isLoading: selectedStudy.isLoading,
          studyId: selectedStudy.studyId,
          base_model_prediction: selectedStudy.base_model_prediction|| {},
          experimental_model_prediction: selectedStudy.experimental_model_prediction|| {},
          seriesUrls: selectedStudy.seriesUrls|| {},
          modelSelectedSeriesId: selectedStudy.selectedSeriesId,
          feedback: selectedStudy.feedback,
          modelType: 'experimental',
          prelim: reports[studyId] || null, 
          prelim_feedback: selectedStudy.prelim_feedback || null,
          report: selectedStudy.report, 
          metadata: selectedStudy.metadata
        }}
        setNavbarConfig={setNavbarConfig}
        onFeedbackUpdate={handleFeedbackUpdate} 
      />
    );
  }

  // Render loading state or worklist
  return (
    <div className="flex flex-col h-screen bg-gray-900 text-white">
      <div className="flex-1 overflow-hidden">
        {isLoading ? (
          <LoadingWorklist />
        ) : (
          <div className="h-full overflow-auto relative">
            <table className="min-w-full">
            <thead className="bg-secondary sticky top-0 z-10">
              <tr>
                <th className="w-12 px-4 py-3 text-left text-xs font-medium text-accent tracking-wider uppercase">
                  Status
                </th>
                <th className="w-48 px-4 py-3 text-left text-xs font-medium text-accent tracking-wider uppercase">
                  <div className="flex items-center gap-2">
                    <span>Study ID</span>
                    <button
                      onClick={refreshStudies}
                      disabled={isRefreshing}
                      className="p-1 rounded hover:bg-gray-700/30 disabled:opacity-50 disabled:cursor-not-allowed"
                      title="Refresh study list"
                    >
                      <FaSync className={`w-3 h-3 ${isRefreshing ? 'animate-spin' : ''}`} />
                    </button>
                  </div>
                </th>
                {/* <th className="w-64 px-4 py-3 text-left text-xs font-medium text-accent tracking-wider uppercase">
                  a2z-1 Predictions
                </th> */}
                <th className="w-96 px-4 py-3 text-left text-xs font-medium text-accent tracking-wider uppercase">
                  <div className="flex justify-between items-center">
                    <span>a2z-2 Predictions</span>
                    {!showPrelims && (
                      <PrelimsToggle showPrelims={showPrelims} setShowPrelims={setShowPrelims} />
                    )}
                  </div>
                </th>
                {showPrelims && (
                  <th className="flex-1 px-4 py-3 text-left text-xs font-medium text-accent tracking-wider uppercase">
                    <div className="flex justify-between items-center">
                      <span>Prelims</span>
                      <PrelimsToggle showPrelims={showPrelims} setShowPrelims={setShowPrelims} />
                    </div>
                  </th>
                )}
              </tr>
            </thead>
              <tbody className="divide-y divide-gray-700/30">
                {studies.map((study, index) => (
                  <StudyRow
                    key={study.studyId}
                    study={study}
                    index={index}
                    navigate={navigate}
                    reports={reports}
                    setReports={setReports}
                    generatingReport={generatingReport}
                    onHover={handleStudyHover} 
                    studies={studies}
                    generateStudyReport={generateStudyReport}
                    showPrelims={showPrelims}
                    token={token}
                    userId={userId}
                    setStudies={setStudies}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Worklist;
